import React from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";

import { Input } from "shared/components/form/inputTypes";
import * as schema from "shared/constants/validation-schema";
import Button from "shared/components/form/button";
import { UserSignupBasicInfoParams } from "../../../interface/login.interface";
import { STEPS } from "../../../helpers";

interface Props {
	step: number | null;
	loading: boolean;
	initialValues: any;
	onSubmit: (payload: UserSignupBasicInfoParams) => void;
}

const SignupBasicInfoForm: React.FC<Props> = (props) => {
	const disableInput = props.step !== STEPS.BASIC_SIGNUP;
	return (
		<Formik
			initialValues={props.initialValues}
			validateOnBlur={true}
			validateOnChange={true}
			onSubmit={props.onSubmit}
			validationSchema={schema.signupBasicInfo}
		>
			{({ handleSubmit, setFieldValue, errors, touched }) => {
				const disabled =
					props.loading ||
					Object.keys(errors).length > 0 ||
					Object.keys(touched).length === 0 ||
					disableInput;
				return (
					<form onSubmit={handleSubmit}>
						<h6 className="form-step-label">
							Step {STEPS.BASIC_SIGNUP + 1} of 4
						</h6>
						<h1 className="form-title">Let’s get started!</h1>
						<div className="form-group">
							<label>
								Your Name
								<span className="text-red">*</span>
							</label>
							<Input
								placeholder="Sam Willson"
								type="text"
								name="name"
								disabled={disableInput}
								showErrorMessage
							/>
						</div>

						<div className="form-group">
							<label>
								Your Email Address{" "}
								<span className="text-red">*</span>
							</label>
							<Input
								placeholder="name@yourbusiness.com"
								type="email"
								name="email"
								autoComplete="off"
								disabled={disableInput}
								showErrorMessage
							/>
						</div>

						<div className="form-group">
							<label>Your Mobile Number</label>
							<Input
								placeholder="+1 555 555 5555"
								type="tel"
								name="mobileNo"
								disabled={disableInput}
								autoComplete="off"
								showErrorMessage
								setFieldValue={setFieldValue}
							/>
						</div>

						<div className="form-group">
							<label>
								Password <span className="text-red">*</span>
							</label>
							<Input
								placeholder="Password"
								type="password"
								name="password"
								autoComplete="new-password"
								disabled={disableInput}
								showErrorMessage
							/>
						</div>

						<Button
							className={`btn btn-green btn-large mb-4 ${
								disabled ? "disable" : ""
							}`}
							loading={props.loading}
							disabled={disabled}
							type="submit"
						>
							Continue
						</Button>

						<p className="text-xs text-gray-100 font-medium mb-6">
							<span className="text-red">*</span> Indicates
							required field.
						</p>
						<p className="text-sm text-gray-100 font-semibold">
							Already have a business account? &nbsp;
							<Link
								to="/login"
								className="text-green hover:text-seagreen"
							>
								Log In
							</Link>
						</p>
					</form>
				);
			}}
		</Formik>
	);
};

export default SignupBasicInfoForm;

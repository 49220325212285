import {Action, IFeedbackFormState} from "../../../../shared/interface";
import {ICategory, IFeedbackFormResponse} from "../../interface/feedback-form.interface";
import actionTypes from "../../../../store/action-types";
import {LoginResponse} from "../../interface/login.interface";
import AuthService from "../../../../shared/services/auth.service";
import {STEPS} from "../../helpers";

const authData: LoginResponse = AuthService.getAuthData() as any;
const savedFormData: IFeedbackFormResponse = AuthService.getFeedbackFormData();

const initialState: IFeedbackFormState = {
	categories: {
		"default-categories": [],
		"custom-categories": [],
	} as { [key: string]: ICategory[] },
	step: (authData.user || {step: undefined}).step || STEPS.BASIC_SIGNUP,
	feedbackFormId: savedFormData._id,
	feedbackFormData: savedFormData
};

const reducer = (state: IFeedbackFormState = initialState, action: Action): IFeedbackFormState => {
	switch (action.type) {
		case actionTypes.FEEDBACK_FORM_CATEGORIES_SUCCESS:
			return FEEDBACK_FORM_CATEGORIES_SUCCESS(state, action);
		case actionTypes.FEEDBACK_FORM_SUCCESS:
			return FEEDBACK_FORM_SUCCESS(state, action);
		case actionTypes.FEEDBACK_SAVED_FORM_SUCCESS:
			return FEEDBACK_SAVED_FORM_SUCCESS(state, action);
		case actionTypes.UPDATE_FEEDBACK_FORM_SUCCESS:
			return UPDATE_FEEDBACK_FORM_SUCCESS(state, action);
		case actionTypes.FEEDBACK_FORM_SLUG_SUCCESS:
			return FEEDBACK_FORM_SLUG_SUCCESS(state, action);
		case actionTypes.DISABLE_GO_NEXT:
			return {
				...state,
				feedbackFormData: {
					...state.feedbackFormData,
					goNext: undefined
				}
			};
		default:
			return state;
	}
};

const FEEDBACK_FORM_CATEGORIES_SUCCESS = (state: IFeedbackFormState, action: Action): IFeedbackFormState => ({
	...state,
	categories: {
		...state.categories,
		[action.payload["_id"]]: action.payload.categories
	}
});

const FEEDBACK_FORM_SUCCESS = (state: IFeedbackFormState, action: Action): IFeedbackFormState => ({
	...state,
	step: action.payload.onDashboard ? state.step : STEPS.REPRESENTATIVE,
	feedbackFormId: action.payload._id,
	feedbackFormData: action.payload
});

const FEEDBACK_FORM_SLUG_SUCCESS = (state: IFeedbackFormState, action: Action): IFeedbackFormState => ({
	...state,
	feedbackFormId: action.payload._id,
	feedbackFormData: action.payload
});

const UPDATE_FEEDBACK_FORM_SUCCESS = (state: IFeedbackFormState, action: Action): IFeedbackFormState => ({
	...state,
	step: action.payload.onDashboard ? state.step : STEPS.REPRESENTATIVE,
	feedbackFormId: action.payload._id,
	feedbackFormData: action.payload
});

const FEEDBACK_SAVED_FORM_SUCCESS = (state: IFeedbackFormState, action: Action): IFeedbackFormState => ({
	...state,
	feedbackFormId: action.payload._id,
	feedbackFormData: action.payload
});

export {reducer as feedbackFormReducer};

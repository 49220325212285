import React from "react";
import { ErrorMessage } from "formik";
import FieldErrorMessage from "./error";
import "react-phone-number-input/style.css";
import { getValue, RenderReusableInput } from "./index";
import ReactPhoneInput from "react-phone-input-2";

interface TextFieldProps {
	disabled?: boolean;
	showErrorMessage?: boolean;
	className?: string;
	autoComplete?: string;
	readOnly?: Boolean;
	name: string;
	placeholder: string;
	type:
		| "text"
		| "email"
		| "password"
		| "number"
		| "textarea"
		| "tel"
		| "url"
		| "hidden";
	setFieldValue?: (field: string, value: string) => void;
	onBlur?: (e?: any) => void;
}

const RenderInput: React.FC<TextFieldProps & { field: any; form: any }> = (
	props
) => {
	const handleChange = (event?: any) => {
		const {
			field: { name },
			form: { handleChange, setFieldTouched },
		} = props;
		setFieldTouched(name, true);
		if (event) {
			handleChange(event);
		}
	};

	const handleOnPhoneChange = (value: any) => {
		props.setFieldValue && props.setFieldValue(props.name, value);
	};

	if (props.type === "textarea") {
		return (
			<>
				<textarea
					{...props.field}
					readOnly={props.readOnly}
					placeholder={props.placeholder}
					className={`${props.className || ""} form-control`}
					id={props.name}
					value={getValue(props.field.value)}
					disabled={props.disabled}
				/>
				{props.showErrorMessage && (
					<ErrorMessage
						name={props.name}
						component={FieldErrorMessage}
					/>
				)}
			</>
		);
	}
	if (props.type === "tel") {
		let cls = "react-tel-input";
		cls += props.disabled ? " disable" : "";

		return (
			<>
				<ReactPhoneInput
					inputProps={{
						name: props.name,
						required: true,
						// autoFocus: true,
						disabled: props.disabled,
					}}
					autoFormat={true}
					inputClass={"phone-input"}
					containerClass={cls}
					countryCodeEditable={false}
					enableSearch={true}
					country={"us"}
					value={props.field.value}
					placeholder={props.placeholder}
					onChange={handleOnPhoneChange}
				/>
				{props.showErrorMessage && (
					<ErrorMessage
						name={props.name}
						component={FieldErrorMessage}
					/>
				)}
			</>
		);
	}
	return (
		<>
			<input
				{...props.field}
				onBlur={props.onBlur || null}
				readOnly={props.readOnly}
				value={getValue(props.field.value)}
				id={props.name}
				type={props.type}
				className={`${props.className || ""} form-control`}
				placeholder={props.placeholder}
				autoComplete={`${props.autoComplete || "off"}`}
				disabled={props.disabled}
				onChange={handleChange}
			/>
			{props.showErrorMessage && (
				<ErrorMessage name={props.name} component={FieldErrorMessage} />
			)}
		</>
	);
};

/**
 * common input field component
 * renders input based on the field configuration
 * @param props { field, form: { touched, errors }, ...props }
 */
const Input: React.FC<TextFieldProps> = (props) => {
	return <RenderReusableInput props={props} component={RenderInput} />;
};

export { Input };

import * as Yup from "yup";
import { errorMessages } from "shared/constants/messages";

const forgetPassword = Yup.object().shape({
	email: Yup.string()
		.email(errorMessages.validType("email"))
		.required(errorMessages.required("email"))
		.strict(true),
});

const resetPassword = Yup.object().shape({
	password: Yup.string()
		.min(8, errorMessages.betweenLengthString("password", 8, 16))
		.max(16, errorMessages.betweenLengthString("password", 8, 16))
		.required(errorMessages.required("password"))
		.strict(true),
	confirmPassword: Yup.string()
		.min(8, errorMessages.betweenLengthString("password", 8, 16))
		.max(16, errorMessages.betweenLengthString("password", 8, 16))
		.required(errorMessages.required("password"))
		.strict(true)
		.test("match", "passwords do not match", function (password) {
			return password === this.parent.password;
		}),
});

const login = Yup.object().shape({
	email: Yup.string()
		.email(errorMessages.validType("email"))
		.required(errorMessages.required("email"))
		.strict(true),
	password: Yup.string()
		.required(errorMessages.required("password"))
		.strict(true),
});

const addRepresentative = Yup.object().shape({
	name: Yup.string().required(errorMessages.required("name")).strict(true),
	email: Yup.string()
		.email(errorMessages.validType("email"))
		.required(errorMessages.required("email"))
		.strict(true),
	mobileNo: Yup.string()
		// .required(errorMessages.required("Phone number"))
		// .matches(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/, errorMessages.validType("mobile number"))
		.strict(true),
});

const passwordChangeSchema = Yup.object({
	oldPassword: Yup.string()
		.required(errorMessages.required("password"))
		.min(8, errorMessages.betweenLengthString("password", 8, 16))
		.max(16, errorMessages.betweenLengthString("password", 8, 16))
		.strict(true),
	newPassword: Yup.string()
		.required(errorMessages.required("password"))
		.notOneOf(
			[Yup.ref("oldPassword")],
			"New password must be different from the previous one"
		)
		.min(8, errorMessages.betweenLengthString("password", 8, 16))
		.max(16, errorMessages.betweenLengthString("password", 8, 16))
		.strict(true),
	confirmPassword: Yup.string()
		.oneOf([Yup.ref("newPassword"), null], "Passwords must match")
		.required("Password confirmation is required"),
});

const signupBasicInfo = Yup.object().shape({
	name: Yup.string().required(errorMessages.required("name")).strict(true),
	email: Yup.string()
		.email(errorMessages.validType("email"))
		.required(errorMessages.required("email"))
		.strict(true),
	mobileNo: Yup.string()
		// .matches(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/, errorMessages.validType("mobile number"))
		.strict(true),
	password: Yup.string()
		.min(8, errorMessages.betweenLengthString("password", 8, 16))
		.max(16, errorMessages.betweenLengthString("password", 8, 16))
		.required(errorMessages.required("password"))
		.strict(true),
});
const signupBusinessInfo = Yup.object().shape({
	logoUrl: Yup.object()
		.shape({
			fileData: Yup.mixed(),
		})
		.nullable()
		.strict(true),
	businessName: Yup.string()
		.required(errorMessages.required("business name"))
		.min(2, errorMessages.betweenLengthString("business name", 2, 30))
		.max(30, errorMessages.betweenLengthString("business name", 2, 30))
		.strict(true),

	address: Yup.string()
		.required(errorMessages.required("business address"))
		.min(2, errorMessages.betweenLengthString("business address", 2, 255))
		.max(255, errorMessages.betweenLengthString("business address", 2, 255))
		.strict(true),
	webaddress: Yup.string()
		.matches(
			/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
			errorMessages.validType("URL")
		)
		.strict(true),

	description: Yup.string().strict(true),
});

const signupFeedbackForm = Yup.object({});

const signupTeammateForm = Yup.object().shape({
	reps: Yup.array().of(
		Yup.object().shape({
			name: Yup.string().required("Name is required"),
			email: Yup.string()
				.email(errorMessages.validType("email"))
				.required(errorMessages.required("email"))
				.strict(true),
			mobileNo: Yup.string()
				// .matches(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/, errorMessages.validType("mobile number"))
				.required()
				.strict(true),
		})
	),
});

export {
	login,
	signupBasicInfo,
	addRepresentative,
	forgetPassword,
	signupBusinessInfo,
	signupFeedbackForm,
	resetPassword,
	signupTeammateForm,
	passwordChangeSchema,
};

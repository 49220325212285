import { lazy } from "react";
import { withRouter } from "react-router";

// enable lazy loading, with React.lazy
const Login = withRouter(lazy(() => import("features/auth/container/login")));
const SignUp = withRouter(lazy(() => import("features/auth/container/signup")));
const ForgetPassword = withRouter(
	lazy(() => import("features/auth/container/forgetPassword"))
);
const ResetPassword = withRouter(
	lazy(() => import("features/auth/container/resetPassword"))
);
const Privacy = withRouter(
	lazy(() => import("features/auth/container/privacy"))
);
const Termsservice = withRouter(lazy(() => import("Termsandservice")));

const NotFound = withRouter(lazy(() => import("shared/components/404/404")));
const Notification = withRouter(
	lazy(() => import("shared/components/notification/notification"))
);
export {
	NotFound,
	Login,
	SignUp,
	Notification,
	ResetPassword,
	ForgetPassword,
	Privacy,
	Termsservice,
};

import React from "react";
import { Redirect, Route } from "react-router-dom";
import AuthService from "../../services/auth.service";

export const Authenticate = {
	isAuthenticated(): { step: any; auth: any; isAccessTokenAvailable: any } {
		return AuthService.checkLogin();
	},
};

export const PrivateRoute = ({ component: Component, ...rest }: any) => (
	<Route {...rest}>
		{(props: any) => {
			return Authenticate.isAuthenticated().auth === true ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{ pathname: "/login", state: { from: props.location } }}
				/>
			);
		}}
	</Route>
);

import actionTypes from "store/action-types";
import {Action, AuthState} from "shared/interface";
import AuthService from "shared/services/auth.service";
import {LoginResponse} from "../interface/login.interface";
import {STEPS} from "../helpers";

const authData: LoginResponse = AuthService.getAuthData() as any;

const initialState: AuthState = {
	auth: AuthService.checkLogin().auth,
	userLoginData: authData as any,
	step: (authData.user || {step: undefined}).step || STEPS.BASIC_SIGNUP,
};

const reducer = (state: AuthState = initialState, action: Action): AuthState => {
	switch (action.type) {
		case actionTypes.AUTH_SUCCESS:
			return AUTH_SUCCESS(state, action);
		case actionTypes.NO_ACTION:
			return NO_ACTION(state);
		case actionTypes.AUTH_LOGOUT_SUCCESS:
			return AUTH_LOGOUT_SUCCESS(state, action);
		case actionTypes.SIGNUP_BASIC_INFO_SUCCESS:
			return SIGNUP_BASIC_INFO_SUCCESS(state, action);
		case actionTypes.SIGNUP_BUSINESS_INFO_SUCCESS:
			return SIGNUP_BUSINESS_INFO_SUCCESS(state, action);
		case actionTypes.UPDATE_SIGNUP_BUSINESS_INFO_SUCCESS:
			return UPDATE_SIGNUP_BUSINESS_INFO_SUCCESS(state, action);
		case actionTypes.FETCH_PROFILE_SUCCESS:
			return FETCH_PROFILE_SUCCESS(state, action);
		case actionTypes.DISABLE_GO_NEXT:
			return {
				...state,
				userLoginData: {
					...state.userLoginData,
					goNext: undefined
				}
			};
		default:
			return state;
	}
};

const AUTH_SUCCESS = (state: AuthState, action: Action): AuthState => ({
	...state,
	auth: true,
	userLoginData: action.payload,
	step: (action.payload.user || {step: undefined}).step || STEPS.BASIC_SIGNUP
});

const AUTH_LOGOUT_SUCCESS = (state: AuthState, action: Action): AuthState => ({
	...state,
	auth: false,
	userLoginData: {} as any,
});

const NO_ACTION = (state: AuthState): AuthState => ({
	...state
});

const SIGNUP_BASIC_INFO_SUCCESS = (state: AuthState, action: Action): AuthState => ({
	...state,
	step: STEPS.BUSINESS_SIGNUP,
	userLoginData: action.payload,
});

const SIGNUP_BUSINESS_INFO_SUCCESS = (state: AuthState, action: Action): AuthState => ({
	...state,
	step: STEPS.FEEDBACK_FORM,
	userLoginData: action.payload,
});

const UPDATE_SIGNUP_BUSINESS_INFO_SUCCESS = (state: AuthState, action: Action): AuthState => ({
	...state,
	step: STEPS.FEEDBACK_FORM,
	userLoginData: action.payload,
});

const FETCH_PROFILE_SUCCESS = (state: AuthState, action: Action): AuthState => ({
	...state,
	userLoginData: action.payload,
});

export default reducer;

import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RouteComponentProps, withRouter } from "react-router";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Formik, FormikValues } from "formik";

import * as schema from "shared/constants/validation-schema";
import Button from "shared/components/form/button";
import { Input } from "shared/components/form/inputTypes";
import { API_CONFIG } from "shared/constants/constants";
import HttpService from "shared/services/http.service";
import { Action, NotificationStateProps, State } from "shared/interface";
import Utility from "shared/util/utility";
import FormSpinner from "shared/components/spinner/formSpinner";

interface UIState {
	loading: boolean;
}

class Forgetpassword extends React.Component<
	RouteComponentProps & NotificationStateProps,
	UIState
> {
	state: UIState = {
		loading: false,
	};

	render() {
		return (
			<div className="container">
				<div className="flex flex-wrap">
					<div className="form-white-block w-full sm:w-1/2 md:w-1/7">
						<h1 className="form-title">Forgot Password</h1>
						<p className="text-sm text-gray-100 font-normal mb-8">
							Please enter your email address and we will send you
							an email about how to reset your password.
						</p>
						<Formik
							initialValues={{ email: "" }}
							validateOnBlur={true}
							validateOnChange={true}
							onSubmit={this.onForgetPassword}
							validationSchema={schema.forgetPassword}
						>
							{({ handleSubmit, errors, touched }) => {
								const disabled =
									this.state.loading ||
									Object.keys(errors).length > 0 ||
									Object.keys(touched).length === 0;
								return (
									<form onSubmit={handleSubmit}>
										<div className="form-group">
											<label htmlFor="email">
												Your Email Address{" "}
												<span className="text-red">
													*
												</span>
											</label>
											<Input
												placeholder="name@yourbusiness.com"
												type="email"
												name="email"
												showErrorMessage
											/>
										</div>
										<Button
											className={`btn btn-green btn-large mb-8 w-56 ${
												disabled ? "disable" : ""
											}`}
											loading={this.state.loading}
											disabled={disabled}
											type="submit"
										>
											Reset Password
										</Button>
										<p className="text-sm text-gray-100 font-medium">
											<Link
												to="/login"
												className="text-green hover:text-seagreen"
											>
												Back to Login
											</Link>
										</p>
									</form>
								);
							}}
						</Formik>
						<FormSpinner isSubmitting={this.state.loading} />
					</div>
				</div>
			</div>
		);
	}

	onForgetPassword = (values: FormikValues) => {
		this.setState({ loading: true });
		HttpService.post(API_CONFIG.PATH.FORGET_PASSWORD, values)
			.then(() => {
				this.setState({ loading: false });
				toast.success(
					"Password reset code has been sent to your email",

					{
						toastId: `${Math.random()}`,
						position: "top-right",
						autoClose: 2000,
						onClose: () => {
							this.props.history.push("/login");
						},
					}
				);
			})
			.catch((e) => {
				this.setState({ loading: false });
				this.props.addNotification(e.message, "error");
			});
	};
}

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): NotificationStateProps => ({
	...Utility.getNotificationProps(dispatch),
});

export default connect<{}, NotificationStateProps, {}, State>(
	null,
	mapDispatchToProps
)(withRouter(Forgetpassword));

import React from "react";
import {CheckboxFieldProps} from "./props";

const RenderInput: React.FC<CheckboxFieldProps> = props => {
	return (
		<>
			<input
				{...props}
				id={props.name}
				name={props.name}
				type="checkbox"
				checked={props.checked}
				className={`${props.className || "input-checkinput"}`}
				onChange={props.onChange}
			/>
			<label className="text-field-label flex-auto" htmlFor={props.name}>{props.label}</label>
		</>
	);
};


export {RenderInput as UnManagedCheckbox};

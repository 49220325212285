import React from "react";
import authService from "shared/services/auth.service";
import { LoggedInUser } from "features/auth/interface/login.interface";
export const AuthContext = React.createContext({
	user: {},
	setUser: (user: LoggedInUser) => {},
});
interface AuthContextInterface {
	user: LoggedInUser;
	setUser: (user: LoggedInUser) => void;
}

class AuthProvider extends React.Component<{}, AuthContextInterface> {
	state: AuthContextInterface = {
		user: authService.getAuthUser() as LoggedInUser,
		setUser: (user) => {
			this.setState({ user });
		},
	};

	render() {
		return (
			<AuthContext.Provider value={this.state}>
				{this.props.children}
			</AuthContext.Provider>
		);
	}
}

export default AuthProvider;

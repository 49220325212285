import httpService from "../shared/services/http.service";
import { API_CONFIG } from "shared/constants/constants";

interface IDashboardParams {
	fromDate: Date;
	toDate: Date;
}

export const fetchDashboardData = async (
	key: string,
	_params: IDashboardParams
) => {
	const response = await httpService.get(API_CONFIG.PATH.DASHBOARD, _params);
	return response;
};

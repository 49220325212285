import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import React, { useState } from "react";
import Button from "../../../../../shared/components/form/button";
import AuthService from "shared/services/auth.service";
import { LoggedInUser } from "features/auth/interface/login.interface";
import { Input } from "../../../../../shared/components/form/inputTypes";
import { ICategory } from "../../../interface/feedback-form.interface";
// import { uuid } from "uuidv4";

interface IProps {
	name: string;
}
const user = AuthService.getAuthUser() as LoggedInUser;
const isRestrictedAccess = user.role === "REPRESENTATIVE";

const addCategory = (arrayHelpers: any) => (e: any) => {
	const availableCategories =
		arrayHelpers.form.values["available-categories"];
	const customCategories = arrayHelpers.form.values["custom-categories"];
	const selectedCategories = availableCategories.filter(
		(category: any) => category.selected
	);
	const selectedCustomCategories = customCategories.filter(
		(category: any) => category.selected
	);
	if (selectedCustomCategories.length + selectedCategories.length < 5) {
		arrayHelpers.push({
			name: "",
			selected: true,
			editable: true,
			id: "",
			action: "add",
		});
	}
};
const deleteCategory = ({ remove }: any, index: number) => remove(index);

function NonEditState(props: {
	name: string;
	index: number;
	onPenClick: () => void;
	item: ICategory;
	onChange: (e: any) => void;
}) {
	return (
		<div className="feedback-category-item">
			<div className="chekbox-group">
				<input
					type="checkbox"
					className="input-checkinput"
					name={`${props.name}[${props.index}].name`}
					id={`${props.name}[${props.index}].name`}
					checked={props.item.selected}
					onChange={props.onChange}
				/>
				<label
					htmlFor={`${props.name}[${props.index}].name`}
					className="text-field-label flex-grow"
				>
					{props.item.name}
				</label>
				<Button
					disabled={false}
					loading={false}
					type="button"
					className="btn-sm btn-dark p-0"
					onClick={props.onPenClick}
				>
					<i className="fas fa-pencil-alt text-black hover:text-green" />
				</Button>
			</div>
		</div>
	);
}

function EditState(props: {
	name: string;
	index: number;
	onBlur: (e?: any) => void;
	onRemoveClick: (e: any) => void;
}) {
	return (
		<div className="feedback-category-item feedback-add-category-input">
			<div className="chekbox-group">
				<Input
					name={`${props.name}[${props.index}].name]`}
					placeholder="Add category"
					type="text"
					className="form-control"
					onBlur={props.onBlur}
				/>
				<Button
					disabled={false}
					loading={false}
					type="button"
					className="btn-sm btn-dark text-red"
					onClick={props.onRemoveClick}
				>
					x
				</Button>
			</div>
		</div>
	);
}

export const CustomCategories = ({ name, ...props }: IProps) => {
	const { values } = useFormikContext();
	const [isEdit, setEdit]: any = useState({});

	const removeCategory = (arrayHelpers: any, index: number) => (e: any) => {
		if (isRestrictedAccess) return;
		deleteCategory(arrayHelpers, index);
		setEdit((prevEdit: any) => {
			const prev = prevEdit;
			delete prev[index];
			return { ...prev };
		});
	};

	const onBlur = (index: number) => (e: any) => {
		if (isRestrictedAccess) return;
		if (e.target.value && e.target.value.toString().trim().length > 0) {
			setEdit((prevEdit: any) => ({ ...prevEdit, [index]: false }));
		}
	};

	const onPenClick = (index: number) => () => {
		if (isRestrictedAccess) return;
		setEdit((prevEdit: any) => ({ ...prevEdit, [index]: true }));
	};

	const onChange = (arrayHelpers: FieldArrayRenderProps, index: number) => (
		e: any
	) => {
		const customCategories = values[name];
		const availableCategories = values["available-categories"];
		const selectedCategories = availableCategories.filter(
			(category: any) => category.selected
		);
		const selectedCustomCategories = customCategories.filter(
			(category: any) => category.selected
		);
		const d = customCategories[index];
		if (e.target.checked) {
			if (
				selectedCategories.length + selectedCustomCategories.length >=
				5
			) {
				return;
			}
			d.selected = true;
			d.action = "select";
		} else {
			d.selected = false;
			d.action = "disselect";
		}
		values[name][index] = d;
		arrayHelpers.form.setFieldValue(
			name,
			values[name].filter((i: ICategory) => i.selected || i.action)
		);
	};

	return (
		<FieldArray name={name}>
			{(arrayHelpers: FieldArrayRenderProps) => {
				return (
					<>
						{values[name] &&
							values[name].map(
								(item: ICategory, index: number) => {
									return (
										<React.Fragment
											key={`${name}-${index}`}
										>
											{isEdit[index] === false ? (
												<NonEditState
													name={name}
													index={index}
													onPenClick={onPenClick(
														index
													)}
													item={item}
													onChange={onChange(
														arrayHelpers,
														index
													)}
												/>
											) : (
												<EditState
													name={name}
													index={index}
													onBlur={onBlur(index)}
													onRemoveClick={removeCategory(
														arrayHelpers,
														index
													)}
												/>
											)}
										</React.Fragment>
									);
								}
							)}
						{!isRestrictedAccess && (
							<Button
								disabled={false}
								loading={false}
								type="button"
								className="btn-sm btn-dark hover:bg-green-100 rounded-full"
								onClick={addCategory(arrayHelpers)}
							>
								+ Add custom category
							</Button>
						)}
					</>
				);
			}}
		</FieldArray>
	);
};

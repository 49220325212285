import React, { lazy, Suspense } from "react";
import DashboardLayout from "../../../shared/hoc/layout/component/dashboard";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import Spinner from "shared/components/spinner/spinner";
import { LoggedInUser } from "features/auth/interface/login.interface";
import AuthService from "shared/services/auth.service";
import Help from "./help";
import HomeDashboard from "./dashboard";
import DashboardHeader from "../components/header";
import Notification from "../../../shared/components/notification/notification";

const CommentListing = lazy(() => import("./feedback"));
const Profile = lazy(() => import("./profile"));
const Representative = lazy(() => import("./representatives"));
const Comments = lazy(() => import("./comments"));
const BusinessInfo = lazy(() =>
	import("features/dashboard/containers/business/index")
);
const Dashboard: React.FC<RouteComponentProps> = (props) => {
	const user = AuthService.getAuthUser() as LoggedInUser;
	const isRestrictedAccess = user.role === "REPRESENTATIVE";

	return (
		<DashboardLayout>
			<Notification />
			<DashboardHeader pathName={props.location.pathname} />
			<Suspense fallback={<Spinner />}>
				<Switch>
					{!isRestrictedAccess && (
						<Route
							component={Representative}
							path={`/representatives`}
							exact
						/>
					)}
					<Route
						component={CommentListing}
						// path={`${url}/comment`}
						path={`/comment/:id?`}
						exact
					/>
					<Route component={Comments} path={`/comments`} exact />
					<Route
						component={BusinessInfo}
						path={`/business/info`}
						exact
					/>
					<Route component={Profile} path={`/profile`} exact />
					<Route component={Help} path={`/help`} exact />
					<Route
						component={HomeDashboard}
						// path="/(dashboard)?"
						path="/dashboard"
						exact
					/>
					<Redirect from="/" to="/comment" />
					<Redirect to="/404" />
				</Switch>
			</Suspense>
		</DashboardLayout>
	);
};

export default Dashboard;

import React, { useState } from "react";
import cloneDeep from "lodash.clonedeep";
import format from "date-fns/format";
import startOfWeek from "date-fns/startOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import startOfYear from "date-fns/startOfYear";
import AutoSizer from "react-virtualized-auto-sizer";

import { Line } from "@nivo/line";
// import CustomSymbol from "./CustomSymbol";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const colorPalettes = [
	"#fe4365",
	"#c7f464",
	"#031634",
	"#bd1550",
	"#f8ca00",
	"#00a0b0",
	"#1c140d",
	"#cbe86b",
	"#ff4e50",
	"#2a363b",
	"#655643",
	"#80bca3",
	"#e6ac27",
	"#bf4d28",
	"#351330",
	"#e8caa4",
	"#cc2a41",
	"#554236",
	"#f77825",
	"#d3ce3d",
	"#f1efa5",
	"#60b99a",
	"#5d4157",
	"#838689",
	"#a8caba",
	"#cad7b2",
	"#ebe3aa",
	"#8c2318",
	"#5e8c6a",
	"#88a65e",
	"#bfb35a",
	"#f2c45a",
	"#fad089",
	"#ff9c5b",
	"#f5634a",
	"#ed303c",
	"#3b8183",
	"#ff4242",
	"#f4fad2",
	"#d4ee5e",
	"#e1edb9",
	"#f0f2eb",
	"#f8b195",
	"#f67280",
	"#c06c84",
	"#6c5b7b",
	"#355c7d",
];

const data = [
	{
		id: "Cleanliness",
		data: [
			{ x: "2018-01-01", y: 1 },
			{ x: "2018-01-02", y: 5 },
			{ x: "2018-01-03", y: 3 },
			{ x: "2018-01-04", y: 2 },
			{ x: "2018-01-05", y: 5 },
			{ x: "2018-01-06", y: 0 },
			{ x: "2018-01-07", y: 2 },
			{ x: "2018-01-08", y: 5 },
			{ x: "2018-01-09", y: 1 },
			{ x: "2018-01-10", y: 5 },
			{ x: "2018-01-11", y: 3 },
			{ x: "2018-01-12", y: 2 },
			{ x: "2018-01-13", y: 5 },
			{ x: "2018-01-14", y: 0 },
			{ x: "2018-01-15", y: 2 },
			{ x: "2018-01-16", y: 5 },
			{ x: "2018-01-17", y: 1 },
			{ x: "2018-01-18", y: 5 },
			{ x: "2018-01-19", y: 3 },
			{ x: "2018-01-20", y: 2 },
			{ x: "2018-01-21", y: 5 },
			{ x: "2018-01-22", y: 0 },
			{ x: "2018-01-23", y: 2 },
			{ x: "2018-01-24", y: 5 },
			{ x: "2018-01-25", y: 1 },
			{ x: "2018-01-26", y: 5 },
			{ x: "2018-01-27", y: 3 },
			{ x: "2018-01-28", y: 2 },
			{ x: "2018-01-29", y: 5 },
			{ x: "2018-01-30", y: 0 },
			{ x: "2018-01-31", y: 2 },
			{ x: "2018-02-01", y: 5 },
			{ x: "2018-02-02", y: 1 },
			{ x: "2018-02-03", y: 5 },
			{ x: "2018-02-04", y: 3 },
			{ x: "2018-02-05", y: 2 },
			{ x: "2018-02-06", y: 5 },
			{ x: "2018-02-07", y: 0 },
			{ x: "2018-02-08", y: 2 },
			{ x: "2018-02-09", y: 5 },
			{ x: "2018-02-10", y: 5 },
			{ x: "2018-02-11", y: 3 },
			{ x: "2018-02-12", y: 2 },
			{ x: "2018-02-13", y: 5 },
			{ x: "2018-02-14", y: 0 },
			{ x: "2018-02-15", y: 2 },
			{ x: "2018-02-16", y: 5 },
			{ x: "2018-02-17", y: 1 },
			{ x: "2018-02-18", y: 5 },
			{ x: "2018-02-19", y: 3 },
			{ x: "2018-02-20", y: 2 },
			{ x: "2018-02-21", y: 5 },
			{ x: "2018-02-22", y: 0 },
			{ x: "2018-02-23", y: 2 },
			{ x: "2018-02-24", y: 5 },
		],
	},
	{
		id: "Air Conditioning",
		data: [
			{ x: "2018-01-04", y: 4 },
			{ x: "2018-01-05", y: 3 },
			{ x: "2018-01-06", y: 1 },
			{ x: "2018-01-07", y: 0 },
			{ x: "2018-01-08", y: 2 },
			{ x: "2018-01-09", y: 4 },
			{ x: "2018-01-10", y: 2 },
			{ x: "2018-01-11", y: 3 },
		],
	},
];

interface ILineProps {
	ratingData: [];
	duration: string;
}
const findAxisBottomTimeFormat = (duration: string) => {
	let axisBottomTimeFormat = "";
	switch (duration) {
		case "week":
			axisBottomTimeFormat = "%b %d";
			break;

		case "month":
			axisBottomTimeFormat = "%b, %Y";
			break;

		case "year":
			axisBottomTimeFormat = "%Y";
			break;
		default:
			axisBottomTimeFormat = "%b %d";
	}
	return axisBottomTimeFormat;
};

const MyResponsiveLine: React.FC<ILineProps> = ({ ratingData, duration }) => {
	const [selectedCategory, setSelectedCategory] = useState("");
	let finalData: any = [];
	const allCategories: any = {};
	const collectionOfAllCategories: string[] = [];
	const normalizedISOtime: any = {};
	let timeAdjustedRatingData: any = [];
	let axisBottomTimeFormat = findAxisBottomTimeFormat(duration);
	const newRatingData = cloneDeep(ratingData);
	newRatingData.forEach((dayRating: any) => {
		if (duration !== "day") {
			for (let day in dayRating) {
				let startMethod;
				if (duration === "week") {
					startMethod = startOfWeek;
				} else if (duration === "month") {
					startMethod = startOfMonth;
				} else {
					startMethod = startOfYear;
				}
				const normalizedDuration = format(
					startMethod(new Date(day)),
					"yyyy-MM-dd"
				);
				if (!normalizedISOtime[normalizedDuration]) {
					normalizedISOtime[normalizedDuration] = dayRating[day];
				} else {
					dayRating[day].categories.forEach((category: any) => {
						const index = normalizedISOtime[
							normalizedDuration
						].categories.findIndex(
							(c: any) => c.name === category.name
						);
						if (index === -1) {
							normalizedISOtime[
								normalizedDuration
							].categories.push(category);
						} else {
							const previousRecord =
								normalizedISOtime[normalizedDuration]
									.categories[index];
							const { count, average } = previousRecord;
							const newAverage =
								(parseFloat(average) * count +
									parseFloat(category.average) *
										category.count) /
								(category.count + count);
							previousRecord.average = newAverage.toFixed(1);
							previousRecord.count = count + category.count;
						}
					});
				}
			}
		} else {
			timeAdjustedRatingData = newRatingData;
		}
	});
	for (let date in normalizedISOtime) {
		timeAdjustedRatingData.push({
			[date]: normalizedISOtime[date],
		});
	}
	timeAdjustedRatingData.forEach((dayRating: any) => {
		for (let day in dayRating) {
			dayRating[day].categories.forEach((category: any) => {
				if (allCategories[category.name]) return;
				allCategories[category.name] = true;
			});
		}
	});
	for (let individualCategory in allCategories) {
		collectionOfAllCategories.push(individualCategory);
		finalData.push({ id: individualCategory, data: [] });
		timeAdjustedRatingData.forEach((dayRating: any) => {
			for (let day in dayRating) {
				dayRating[day].categories.forEach((category: any) => {
					if (category.name === individualCategory) {
						if (!selectedCategory) {
							finalData[finalData.length - 1].data.push({
								x: format(new Date(day), "yyyy-MM-dd"),
								y: parseFloat(category.average),
							});
						} else if (category.name === selectedCategory) {
							finalData[finalData.length - 1].data.push({
								x: format(new Date(day), "yyyy-MM-dd"),
								y: parseFloat(category.average),
							});
						}
					}
				});
			}
		});
	}
	return (
		<>
			<div className="chart-legends">
				{collectionOfAllCategories.map((category, i) => (
					<div
						className={`legend-item ${
							category === selectedCategory ? "active" : ""
						}`}
						key={category}
						onClick={() => {
							if (selectedCategory === category) {
								setSelectedCategory("");
							} else {
								setSelectedCategory(category);
							}
						}}
					>
						<span
							className="legend-circle"
							style={{
								borderColor: colorPalettes[i],
							}}
						/>
						<span>{category}</span>
					</div>
				))}
			</div>
			<div className="line-chart-height">
				<AutoSizer>
					{({ height, width }) => (
						<Line
							height={height}
							width={width}
							enableSlices="x"
							colors={(line) => {
								const index = collectionOfAllCategories.findIndex(
									(element) => element === line.id
								);
								return colorPalettes[index];
							}}
							data={finalData}
							curve="linear"
							axisLeft={{
								legendOffset: 12,
								tickValues: [0, 1, 2, 3, 4, 5],
								tickPadding: 5,
							}}
							axisBottom={{
								format: axisBottomTimeFormat,
								tickValues:
									timeAdjustedRatingData.length <= 5
										? `every ${duration}`
										: Math.min(
												timeAdjustedRatingData.length,
												8
										  ),
								legendOffset: -12,
								tickSize: 0,
							}}
							margin={{
								top: 50,
								right: 50,
								bottom: 80,
								left: 20,
							}}
							xScale={{
								type: "time",
								format: "%Y-%m-%d",
								useUTC: false,
								precision: "day",
							}}
							xFormat="time:%b %d %Y"
							yScale={{
								type: "linear",
								max: 6,
							}}
							theme={{
								grid: {
									line: {
										stroke: "#dedede",
										strokeWidth: 0.5,
										strokeDasharray: "3 3",
									},
								},
							}}
							gridYValues={6}
							axisTop={null}
							axisRight={null}
							pointSize={10}
							pointColor={{ theme: "background" }}
							pointBorderWidth={2}
							pointBorderColor={{ from: "serieColor" }}
							pointLabel="y"
							pointLabelYOffset={-12}
							useMesh={true}
							lineWidth={1}
							// legends={[
							// 	{
							// 		anchor: "top-left",
							// 		direction: "row",
							// 		translateX: -10,
							// 		translateY: -50,
							// 		itemDirection: "left-to-right",
							// 		itemWidth: 75,
							// 		itemsSpacing: 10,
							// 		itemHeight: 20,
							// 		itemOpacity: 1,
							// 		symbolSize: 12,
							// 		symbolShape: CustomSymbol,
							// 		symbolBorderColor: "rgba(0, 0, 0, .5)",
							// 	},
							// ]}
							motionStiffness={190}
							motionDamping={39}
						/>
					)}
				</AutoSizer>
			</div>
		</>
	);
};
export default MyResponsiveLine;

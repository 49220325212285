import React, { ChangeEvent } from "react";
import { Formik } from "formik";
import { Input } from "shared/components/form/inputTypes";
import * as schema from "shared/constants/validation-schema";
import Button from "shared/components/form/button";
import FieldErrorMessage from "shared/components/form/error";
import uploadIcon from "assets/images/upload-logo.svg";

import { UserSignupBusinessInfoParams } from "../../../interface/login.interface";
import Spinner from "shared/components/spinner/spinner";
import { STEPS } from "../../../helpers";
import GooglePlacesAutoComplete from "shared/components/googlePlacesAutoComplete/googlePlacesAutoComplete";

interface Props {
	step: number | null;
	loading: boolean;
	loadingImage: boolean;
	initialValues: any;
	onSubmit: (payload: UserSignupBusinessInfoParams) => void;
}

const SignupBusinessInfoForm: React.FC<Props> = (props) => {
	const onChange = (setFieldError: any, setFieldValue: any) => (
		e: ChangeEvent<HTMLInputElement>
	) => onLogoChange(e, setFieldError, setFieldValue);

	return (
		<Formik
			initialValues={props.initialValues}
			validateOnBlur={true}
			validateOnChange={true}
			onSubmit={props.onSubmit}
			validationSchema={schema.signupBusinessInfo}
		>
			{({
				handleSubmit,
				setFieldValue,
				setFieldError,
				values,
				errors,
				touched,
			}) => {
				const disabled =
					props.loading ||
					props.loadingImage ||
					((Object.keys(errors).length > 0 ||
						Object.keys(touched).length === 0) &&
						props.step === STEPS.BUSINESS_SIGNUP);
				return (
					<form onSubmit={handleSubmit}>
						<h6 className="form-step-label">
							Step {STEPS.BUSINESS_SIGNUP + 1} of 4
						</h6>
						<h1 className="form-title">Add business information</h1>

						<div className="upload-file-block">
							<div className="upload-file-button">
								{values.logoUrl &&
								(values.logoUrl as any).base64 ? (
									<span className="upload-file-icon image-preview">
										{props.loadingImage ? (
											<Spinner />
										) : (
											//@ts-ignore
											<div>
												<img
													src={
														(values.logoUrl as any)
															.base64
													}
													alt="icon"
													title="upload file"
												/>
												<span
													className="remove-image"
													onClick={() =>
														setFieldValue(
															"logoUrl",
															null
														)
													}
												>
													×
												</span>
											</div>
										)}
									</span>
								) : (
									<span className="upload-file-icon">
										<img
											src={uploadIcon}
											alt="icon"
											title="upload file"
										/>
									</span>
								)}
								<input
									onChange={onChange(
										setFieldError,
										setFieldValue
									)}
									onClick={onInputClick}
									type="file"
									name="upload"
									id="upload"
									className="upload-input"
									placeholder="Upload File"
								/>
								<div className="ml-4">
									<h6 className="font-semibold text-gray-100">
										<label htmlFor="upload">
											Upload Business Logo
										</label>
									</h6>
									<p className="file-upload-intruction">
										(Maximum size of 2MB. JPG, GIF, PNG)
									</p>
								</div>
							</div>
						</div>
						<FieldErrorMessage>{errors.logoUrl}</FieldErrorMessage>

						<div className="form-group mt-2">
							<label htmlFor="businessName">
								Business Name{" "}
								<span className="text-red">*</span>
							</label>
							<Input
								placeholder="Sam Pizza"
								type="text"
								name="businessName"
								showErrorMessage
							/>
						</div>

						<div className="form-group">
							<label htmlFor="address">
								Business Full Address{" "}
								<span className="text-red">*</span>
							</label>
							<GooglePlacesAutoComplete
								name="address"
								placeholder="453 Grady Mountains"
								className="input-textarea"
								value={values.address}
								onPlaceSelect={setFieldValue}
								showErrorMessage
							/>
						</div>

						<div className="form-group">
							<label htmlFor="webaddress">Web Address</label>
							<Input
								placeholder="http://www.companyaddress.com"
								type="text"
								name="webaddress"
								showErrorMessage
							/>
						</div>

						<div className="form-group">
							<label htmlFor="description">
								About Business
								{/* <span className="text-red">*</span> */}
							</label>
							<Input
								placeholder="E.g Mengo Cafe & Bar is the country's first completely indigenous and eclectic café chain"
								type="textarea"
								name="description"
								showErrorMessage
							/>
						</div>

						<Button
							className={`btn btn-green btn-large mb-4 ${
								disabled ? "disable" : ""
							}`}
							loading={props.loading}
							disabled={disabled}
							type="submit"
						>
							Continue
						</Button>
						<p className="text-xs text-gray-100 font-medium mb-6">
							<span className="text-red">*</span> Indicates
							required field.
						</p>
					</form>
				);
			}}
		</Formik>
	);
};

export const onInputClick = (
	event: React.MouseEvent<HTMLInputElement, MouseEvent>
) => {
	const element = event.target as HTMLInputElement;
	element.value = "";
};
export const onLogoChange = (
	e: React.ChangeEvent<HTMLInputElement>,
	setFieldError: any,
	setFieldValue: any
) => {
	const files = e.target.files;
	if (!files || !files[0]) {
		// setFieldError("logoUrl", "please provide file");
		return;
	}
	const file = files[0];
	const type = file.type.toLowerCase();
	if (!type.startsWith("image/")) {
		setFieldError("logoUrl", "please provide valid image file");
		return;
	}
	const allowedTypes = ["jpg", "jpeg", "png", "gif"];
	if (!allowedTypes.includes(type.split("/")[1])) {
		setFieldError("logoUrl", "only JPG, GIF, PNG files are allowed");
		return;
	}
	if (file.size > 2000000) {
		setFieldError("logoUrl", "Maximum size of 2MB is allowed");
		return;
	}
	const fileReader = new FileReader();
	fileReader.onload = () => {
		const fileData = {
			fileData: file,
			base64: fileReader.result,
		};
		setFieldError("logoUrl", "");
		setFieldValue("logoUrl", fileData);
	};
	fileReader.readAsDataURL(file);
};

export default SignupBusinessInfoForm;

import React, { useCallback } from "react";
import * as schema from "../../../../../shared/constants/validation-schema";
import { Formik } from "formik";
import Button from "../../../../../shared/components/form/button";
import { LoggedInUser } from "features/auth/interface/login.interface";
import AuthService from "shared/services/auth.service";
import { ICategory } from "../../../interface/feedback-form.interface";
import { CustomCategories } from "./CustomCategories";
import { RatingComment } from "../../../../../shared/components/ratingComment/ratingComment";
import { Input } from "../../../../../shared/components/form/inputTypes";
import { STEPS } from "../../../helpers";
import CheckBoxCategories from "./checkboxCategories";
import * as H from "history";
import useEventListener from "shared/hooks/eventListeners";

const user = AuthService.getAuthUser() as LoggedInUser;
const isRestrictedAccess = user.role === "REPRESENTATIVE";
interface Props {
	loading: boolean;
	onSubmit: (payload: any, form?: any) => void;
	step?: number | null;
	title?: string;
	initialValues: any;
	availableCategories: ICategory[];
	feedbackCategories?: {};
	history: H.History;
}

const sortCategories = (a: ICategory, b: ICategory) => {
	if (a.selected && !b.selected) return -1;
	if (!a.selected && b.selected) return 1;
	return 0;
};

const SignupFeedbackForm: React.FC<Props> = (props) => {
	const { availableCategories, history, feedbackCategories } = props;

	const keyDownListener = useCallback((e: any) => {
		if (
			e.keyIdentifier === "U+000A" ||
			e.key === "Enter" ||
			e.keyCode === 13
		) {
			e.preventDefault();
			e.target.blur();
			return false;
		}
	}, []);
	useEventListener("keydown", keyDownListener);

	return (
		<Formik
			enableReinitialize
			initialValues={props.initialValues}
			validateOnBlur={true}
			validateOnChange={true}
			onSubmit={props.onSubmit}
			validationSchema={schema.signupFeedbackForm}
		>
			{({ handleSubmit, values, errors }) => {
				const disabled =
					props.loading || Object.keys(errors).length > 0;

				availableCategories.sort(sortCategories);

				return (
					<form onSubmit={handleSubmit}>
						<Input name="form-id" type="hidden" placeholder="" />
						<h6 className="form-step-label">
							{props.step &&
								`Step ${STEPS.FEEDBACK_FORM + 1} of 4`}
						</h6>
						<h1 className="form-title">{props.title}</h1>
						<h4 className="text-sm text-gray-100 font-semibold mb-2">
							Select Categories wanted to list out in Feedback
						</h4>
						<p className="selection-instruction mb-6 font-medium">
							(You may only select upto a maximum of 5)
						</p>
						<div className="feedback-category">
							<CheckBoxCategories
								name="available-categories"
								feedbackCategories={feedbackCategories}
								categories={availableCategories}
							/>
							<CustomCategories name="custom-categories" />
						</div>
						{/*<div className="mb-5">*/}
						{/*	<Toggle label="Send Auto Replies" name="auto-reply-on"*/}
						{/*			on={values["auto-reply-on"]}/>*/}
						{/*</div>*/}

						{values["auto-reply-on"] ? (
							<>
								{/*<RatingComment*/}
								{/*	starCount={4}*/}
								{/*	showRatingDescription*/}
								{/*	name="high-rating"*/}
								{/*	label="High Rating Comment"*/}
								{/*	startDescription="is consider as high rating"*/}
								{/*	startDescriptionSign="&gt;"*/}
								{/*	placeholder="Thanks. Glad you love this. Please give us a chance of serving you again."*/}
								{/*/>*/}
								{/*<RatingComment*/}
								{/*	starCount={3}*/}
								{/*	showRatingDescription*/}
								{/*	name="low-rating"*/}
								{/*	label="Low Rating Comment"*/}
								{/*	startDescription="is consider as low rating"*/}
								{/*	startDescriptionSign="&lt;"*/}
								{/*	placeholder="Thank you for submitting your comment. We will reply shortly."*/}
								{/*/>*/}
								<RatingComment
									name="default-rating"
									readOnly={isRestrictedAccess}
									label="What do you want as your auto reply?"
									startDescription="is consider as low rating"
									startDescriptionSign="&lt;"
									placeholder="Provide your autoreply message"
								/>
							</>
						) : (
							""
						)}

						<div className="flex content-between justify-around flex-wrap">
							{props.step === undefined ? (
								<Button
									className={`btn btn-green btn-large mb-4 ${
										disabled || isRestrictedAccess
											? "disable"
											: ""
									}`}
									loading={props.loading}
									disabled={disabled || isRestrictedAccess}
									type="submit"
								>
									Save Changes
								</Button>
							) : (
								<>
									<Button
										className="btn bg-gray-400 btn-large mb-4"
										loading={props.loading}
										onClick={() =>
											history.push(
												`/signup/${STEPS.BUSINESS_SIGNUP}`
											)
										}
										type="button"
									>
										<i className="fa fa-arrow-left" /> Back
									</Button>

									<Button
										className={`btn btn-green btn-large mb-4 ${
											disabled ? "disable" : ""
										}`}
										loading={props.loading}
										disabled={disabled}
										type="submit"
									>
										Continue
									</Button>
								</>
							)}
						</div>
					</form>
				);
			}}
		</Formik>
	);
};

export default SignupFeedbackForm;

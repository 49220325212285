import * as H from "history";

export enum STEPS {
	BASIC_SIGNUP = 0,
	BUSINESS_SIGNUP,
	FEEDBACK_FORM,
	REPRESENTATIVE
}

export const next = (history?: H.History, step?: number | null) => {
	if (history && step) {
		return history.push(`/signup/${step}`);
	}

	if (history && step === null) {
		return history.push("/dashboard");
	}

};

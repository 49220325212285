import React from "react";
import Utility from "../../../../../shared/util/utility";
import {
	Action,
	IFeedbackFormState,
	LoaderState,
	NotificationStateProps,
	State,
} from "../../../../../shared/interface";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import FeedbackFormActions from "../../../store/feedback-form/actions";
import {
	FeedbackFormParams,
	ICategory,
	UpdateFeedbackFormParams,
} from "../../../interface/feedback-form.interface";
import SignupFeedbackForm from "./signupFeedbackForm";
import { STEPS } from "../../../helpers";
import * as H from "history";
import { Redirect, RouteComponentProps } from "react-router";
import { getStep } from "../../../../../shared/constants/constants";
import actionTypes from "../../../../../store/action-types";

interface DispatchProps extends NotificationStateProps {
	disableGoNext: () => void;
	fetchDefaultCategories: () => void;
	fetchSavedFormDetails: (id?: string) => void;
	onSignupFeedbackForm: (
		payload: FeedbackFormParams,
		history?: H.History
	) => void;
	updateFeedbackForm: (
		payload: UpdateFeedbackFormParams,
		history?: H.History
	) => void;
}

class FeedbackForm extends React.Component<
	IFeedbackFormState & RouteComponentProps & DispatchProps & LoaderState,
	any
> {
	componentDidMount(): void {
		if (this.props.step && this.props.step < STEPS.FEEDBACK_FORM) {
			this.props.history.replace(`/signup/${this.props.step}`);
			return;
		}
		this.props.fetchDefaultCategories();
		this.props.fetchSavedFormDetails(this.props.feedbackFormId);
	}

	render() {
		if (this.props.feedbackFormData.goNext) {
			this.props.disableGoNext();
			return <Redirect to={`/signup/${this.props.step}`} />;
		}

		const { availableCategories }: any = this.mergeCategories();
		const initials: any = this.initialValues();
		initials["available-categories"] = availableCategories;

		return (
			<SignupFeedbackForm
				title="Setup feedback form"
				availableCategories={availableCategories}
				step={this.props.step}
				loading={this.props.loading}
				initialValues={initials}
				onSubmit={this.onSubmit}
				history={this.props.history}
			/>
		);
	}

	mergeCategories = () => {
		let defaultCats: ICategory[] = [
			...this.props.categories["default-categories"],
			...this.props.categories["custom-categories"],
		];

		let tmp: { [key: string]: ICategory } = {};

		defaultCats.forEach((c: ICategory) => {
			tmp[c.id] = c;
		});

		if (
			!this.props.feedbackFormData ||
			!this.props.feedbackFormData.feedbackCategories
		) {
			return this.separateCategories(tmp);
		}

		return this.separateCategories({
			...tmp,
			...this.props.feedbackFormData.feedbackCategories,
		});
	};

	initialValues = () => {
		const { step } = this.props;
		if (step === STEPS.FEEDBACK_FORM) {
			return {
				"custom-categories": [],
				"default-categories": [],
				"auto-reply-on": true,
				"high-rating": "",
				"low-rating": "",
				"default-rating":
					"Thank you very much for contacting us. We value your feedback and are committed to ensuring your satisfaction",
			};
		}

		const {
			highRating,
			lowRating,
			defaultRating,
			autoReplyOn,
		} = this.props.feedbackFormData;

		return {
			"custom-categories": [],
			"default-categories": [],
			"auto-reply-on": autoReplyOn !== undefined ? autoReplyOn : true,
			"form-url": this.props.feedbackFormData.formUrl,
			"user-id": this.props.feedbackFormData.userId,
			"form-id": this.props.feedbackFormData._id,
			"high-rating": highRating ? highRating.message || "" : "",
			"low-rating": lowRating ? lowRating.message || "" : "",
			"default-rating": defaultRating
				? defaultRating.message || ""
				: "Thank you very much for contacting us. We value your feedback and are committed to ensuring your satisfaction",
		};
	};

	separateCategories = (categories: { [key: string]: ICategory }) => {
		const availableCategories: any[] = [];
		const selectedCategories: any[] = [];

		if (!categories) {
			return { availableCategories, selectedCategories };
		}

		Object.keys(categories).forEach((value: string) => {
			const category: any = categories[value];

			category["id"] = value.toString();

			if (category.selected === undefined) {
				category.selected = false;
			}
			if (category.editable === undefined) {
				category.editable = false;
			}
			if (category.selected) {
				selectedCategories.push(category);
			}
			availableCategories.push(category);
		});
		return { availableCategories, selectedCategories };
	};

	onSubmit = (data_1: any) => {
		const defaultRatingMessage =
			"Thank you very much for contacting us. We value your feedback and are committed to ensuring your satisfaction.";
		let data = {
			...data_1,
			"low-rating": { message: data_1["low-rating"] },
			"high-rating": { message: data_1["high-rating"] },
			"default-rating": {
				message: data_1["default-rating"] || defaultRatingMessage,
			},
		};

		if (data["form-id"]) {
			const dx = data["custom-categories"].map((y: ICategory) => ({
				...y,
				action: "add",
			}));

			let categories: any = [...data["available-categories"], ...dx];
			categories = categories.filter((x: ICategory) => !!x.action);

			data = { ...data, categories };
			delete data["default-categories"];
			delete data["available-categories"];
			delete data["custom-categories"];
			delete data["user-id"];
			delete data["form-url"];

			return this.props.updateFeedbackForm(data, this.props.history);
		} else {
			data["default-categories"] = data["available-categories"]
				.filter((i: ICategory) => {
					if (i.selected === true) {
						return i.id;
					}
				})
				.map((i: ICategory) => i.id);
			data["custom-categories"] = data["custom-categories"]
				.filter((i: ICategory) => {
					if (i.selected === true) {
						return i.name;
					}
				})
				.map((i: ICategory) => i.name);

			delete data["available-categories"];

			this.props.onSignupFeedbackForm(
				data as FeedbackFormParams,
				this.props.history
			);
		}
	};
}

const loadingSelector = Utility.createLoadingSelector([
	"FEEDBACK_FORM",
	"FEEDBACK_SAVED_FORM",
	"UPDATE_FEEDBACK_FORM",
]);

const mapStateToProps = (state: State): IFeedbackFormState & LoaderState => ({
	...state.feedbackForm,
	step: getStep(state),
	loading: loadingSelector(state),
});
const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	...Utility.getNotificationProps(dispatch),
	disableGoNext: () =>
		dispatch(Utility.createAction(actionTypes.DISABLE_GO_NEXT)),
	onSignupFeedbackForm: (payload, history) =>
		dispatch(FeedbackFormActions.signUpFeedbackForm(payload, history)),
	fetchDefaultCategories: () =>
		dispatch(FeedbackFormActions.fetchDefaultCategories()),
	fetchSavedFormDetails: (id?: string) =>
		dispatch(FeedbackFormActions.fetchSavedFormDetails(id)),
	updateFeedbackForm: (payload, history) =>
		dispatch(FeedbackFormActions.updateFeedbackForm(payload, history)),
});

export default connect<IFeedbackFormState, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(FeedbackForm);

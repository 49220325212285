import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = (props) => {
	return (
		<footer className="footer">
			<div className="container">
				<div className="flex flex-wrap">
					<div className="footer-left w-full md:w-2/3 flex items-center">
						<p className="text-gray-100 text-xs font-medium">
							Copyright &copy; {new Date().getFullYear()}
							&nbsp;Von Halle Solutions
						</p>
						<div className="footer-links">
							<Link to="/privacy"> Privacy Policy</Link>
						</div>
					</div>
					<div className="footer-right w-full md:w-2/6 self-center">
						<p className="text-xs text-gray-100">
							Need help?{" "}
							<a
								href="mailto:Info@BizzPing.com"
								className="font-semibold"
							>
								Info@BizzPing.com
							</a>
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

import React from "react";
import {CheckboxFieldProps} from "./props";
import {ManagedCheckbox} from "./managed";
import {UnManagedCheckbox} from "./unmanaged";

/**
 * common checkbox field component
 * renders checkbox based on the field configuration
 * @param props { ...props }
 */
const Checkbox: React.FC<CheckboxFieldProps> = props => {
	if (props.managed) {
		return <ManagedCheckbox {...props} />;
	}
	return <UnManagedCheckbox {...props} />;
};

export {Checkbox};

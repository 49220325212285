import React from "react";
import Header from "./header";
import Footer from "./footer";

const Layout: React.FC = props => {
	return (
		<>
			<Header/>
			<div id="wrapper">
				{props.children}
			</div>
			<Footer/>
		</>
	);
};

export default Layout;

import Star from "../../../assets/images/star.svg";
import { Input } from "../form/inputTypes";
import React from "react";

interface IProps {
	containerClassName?: string;
	inputClassName?: string;
	label?: string;
	readOnly?: boolean;
	startDescription?: string;
	startDescriptionSign?: string;
	placeholder?: string;
	showRatingDescription?: boolean;
	starCount?: number;
	name: string;
}

export const RatingComment: React.FC<IProps> = ({
	starCount = 0,
	...props
}: IProps) => {
	const arr = new Array(starCount).fill(1);
	return (
		<div
			className={`form-group high-rating-comment ${
				props.containerClassName || ""
			}`}
		>
			<label htmlFor={props.name}>
				<div className="high-rating-comment-label">{props.label}</div>
				{props.showRatingDescription ? (
					<div className="high-rating-label">
						{arr.map((i: number, idx: number) => (
							<img
								key={`${i}[${idx}]`}
								src={Star}
								className="star-icon"
								alt=""
								title=""
							/>
						))}
						<div className="flex items-start flex-grow">
							{props.startDescriptionSign}
							<span className="pl-1">
								{props.startDescription}
							</span>
						</div>
					</div>
				) : (
					""
				)}
			</label>
			<Input
				type="textarea"
				readOnly={props.readOnly}
				name={props.name}
				placeholder={props.placeholder || ""}
				className={`form-control ${props.inputClassName || ""}`}
			/>
		</div>
	);
};

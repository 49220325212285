import format from "date-fns/format";
import { ThunkDispatch } from "redux-thunk";
import { toast, Flip } from "react-toastify";
import actionTypes from "store/action-types";
import {
	Action,
	ModalStateProps,
	NotificationStateProps,
	State,
} from "../interface";
import { CommentStatusType } from "aws-sdk/clients/workdocs";

enum SnoozeStatus {
	off = "OFF",
	on = "ON",
}
const createAction = (ACTION: string, data: any = null): Action => {
	return {
		type: ACTION,
		payload: data,
	};
};

const getModalStateProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): ModalStateProps => {
	return {
		openModal: (action) =>
			dispatch(createAction(actionTypes.OPEN_MODAL, action)),
		closeModal: () => dispatch(createAction(actionTypes.CLOSE_MODAL)),
	};
};

const getNotificationProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): NotificationStateProps => {
	return {
		addNotification: (message, type) =>
			dispatch(
				createAction(actionTypes.SET_NOTIFICATION, {
					message,
					type,
				})
			),
	};
};

export const markAsReply = async (
	id: string,
	mutation: Function,
	statusText: CommentStatusType
) => {
	if (statusText !== "Need Reply") return;
	try {
		await mutation(id);
		toast.success("Marked as replied", {
			toastId: `${Math.random()}`,
			position: "bottom-right",
			autoClose: 2000,
			transition: Flip,
		});
	} catch (e) {
		console.log(e);
	}
};

export const changeSnoozeStatusWithToast = async (
	variables: {
		feedbackId: string;
		currentSnoozeStatus: SnoozeStatus;
	},
	mutation: Function
) => {
	try {
		await mutation(variables);
		toast.success("Changed snooze status successfully", {
			toastId: `${Math.random()}`,
			position: "bottom-right",
			autoClose: 2000,
			transition: Flip,
		});
	} catch (e) {
		console.log(e);
	}
};

const createLoadingSelector = (actions: string[]) => (state: State) => {
	// returns true only when all actions is not loading
	let loader = false;
	for (let i = 0; i < actions.length; i += 1) {
		if (state.loading.api[actions[i]]) {
			loader = true;
			break;
		}
	}
	return loader;
};

/**
 * function which returns formatted date
 * @param date
 * @param format
 */
const formatDate = (date: any, dateFormat?: string) => {
	if (!date) {
		return "";
	}
	return format(date, dateFormat || "YYYY-MM-DD HH:mm:ss");
};

const debounce = (func: any, wait = 400) => {
	let h: NodeJS.Timeout;
	return (...args: any[]) => {
		clearTimeout(h);
		h = setTimeout(() => func(...args), wait);
	};
};

const Utility = {
	createAction: createAction,
	getModalStateProps: getModalStateProps,
	createLoadingSelector: createLoadingSelector,
	formatDate: formatDate,
	debounce: debounce,
	getNotificationProps: getNotificationProps,
};

export default Utility;

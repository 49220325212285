import {Action, ITeamMatesFormState} from "../../../../shared/interface";
import actionTypes from "../../../../store/action-types";
import {LoginResponse} from "../../interface/login.interface";
import AuthService from "../../../../shared/services/auth.service";
import {ITeammateFormResponse} from "../../interface/teammates.interface";
import {STEPS} from "../../helpers";

const authData: LoginResponse = AuthService.getAuthData() as any;
const savedTeammateData: ITeammateFormResponse = AuthService.getTeammateData();

const initialState: ITeamMatesFormState = {
	step: (authData.user || {step: undefined}).step || STEPS.BASIC_SIGNUP,
	teammateData: savedTeammateData,
	skippedMatesForm: savedTeammateData.skippedMatesForm || false,
	showSuccessPage: savedTeammateData.showSuccessPage || false
};

const reducer = (state: ITeamMatesFormState = initialState, action: Action): ITeamMatesFormState => {
	switch (action.type) {
		case actionTypes.TEAMMATE_FORM_SUCCESS:
			return TEAMMATE_FORM_SUCCESS(state, action);
		case actionTypes.TEAMMATE_FORM_NO_ACTION:
			return TEAMMATE_FORM_NO_ACTION(state, action);
		case actionTypes.DISABLE_GO_NEXT:
			return {
				...state,
				teammateData: {
					...state.teammateData,
					goNext: undefined
				}
			};
		default:
			return state;
	}
};

const TEAMMATE_FORM_SUCCESS = (state: ITeamMatesFormState, action: Action): ITeamMatesFormState => ({
	...state,
	step: action.payload.step,
	teammateData: {...state.teammateData, ...action.payload},
	skippedMatesForm: action.payload.skippedMatesForm || false,
	showSuccessPage: action.payload.showSuccessPage || false
});

const TEAMMATE_FORM_NO_ACTION = (state: ITeamMatesFormState, action: Action): ITeamMatesFormState => ({
	...state,
	teammateData: action.payload,
	step: action.payload.step || state.teammateData.step,
	skippedMatesForm: action.payload.skippedMatesForm || false,
	showSuccessPage: action.payload.showSuccessPage || false
});

export {reducer as teammateReducer};

import {connect} from "react-redux";
import {ThunkDispatch} from "redux-thunk";

import {Action, State} from "shared/interface";
import Utility from "shared/util/utility";

import LoginActions from "../store/login.action";
import {AuthMapStateProps, LoginDispatchProps, UserLoginParams} from "../interface/login.interface";
import LoginComponent from "../component/loginForm";

const loadingSelector = Utility.createLoadingSelector(["AUTH"]);
const mapStateToProps = (state: State): AuthMapStateProps => ({
	...state.auth,
	loading: loadingSelector(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, Action>): LoginDispatchProps => ({
	onLogin: (payload: UserLoginParams) => dispatch(LoginActions.login(payload)),
});

export default connect<AuthMapStateProps, LoginDispatchProps, {}, State>(mapStateToProps, mapDispatchToProps)(LoginComponent);

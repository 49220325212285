import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Layout from "shared/hoc/layout/component/layout.component";
import Spinner from "shared/components/spinner/spinner";
import Notification from "shared/components/notification/notification";
import SignUp from "features/auth/container/signup";
import Login from "features/auth/container/login";
import ForgetPassword from "features/auth/container/forgetPassword";
import ResetPassword from "features/auth/container/resetPassword";
/*
 * import async components in order to lazy load the containers, which will be rendered conditionally
 * based on the permissions user is having
 */
import * as asyncComponents from "shared/hoc/asyncComponents";
import Dashboard from "./features/dashboard/containers";
import { PrivateRoute } from "./shared/components/privateRoute";
import { ConnectedComponent } from "react-redux";

function withHeaderAndFooter(
	WrappedComponent:
		| React.ComponentClass
		| React.FC
		| ConnectedComponent<any, any>
		| any
) {
	return function Component(props: any) {
		return (
			<Layout>
				<Notification />
				<Suspense fallback={<Spinner />}>
					<WrappedComponent {...props} />
				</Suspense>
			</Layout>
		);
	};
}

const App: React.FC = () => {
	return (
		<Switch>
			<Route component={withHeaderAndFooter(Login)} path="/login" exact />
			<Route
				component={withHeaderAndFooter(SignUp)}
				path="/signup/:step?"
			/>
			<Route
				component={withHeaderAndFooter(ForgetPassword)}
				path="/forget-password"
				exact
			/>
			<Route
				component={withHeaderAndFooter(ResetPassword)}
				path="/reset-password/:token"
			/>
			<Route
				component={withHeaderAndFooter(asyncComponents.Privacy)}
				path="/privacy"
			/>

			<Route
				component={withHeaderAndFooter(asyncComponents.NotFound)}
				path="/404"
			/>

			<PrivateRoute component={Dashboard} path="/(dashboard)?" />
			<Redirect to="/404" />
		</Switch>
	);
};

export default App;

import React from "react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import ReactDOM from "react-dom";
import Root from "./Root";
import "./assets/styles/index.css";
import "./assets/styles/custom.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";

const rootEl = document.getElementById("root");

//@ts-ignore

// Create a reusable render method that we can call more than once
const render = (Component: React.FC) => {
	ReactDOM.render(<Component />, rootEl);
};
render(Root);
if (process.env.REACT_APP_ANALYTICS_ENV === "production") {
	LogRocket.init("hulhju/bizzping");
	setupLogRocketReact(LogRocket);
}

import queryString from "query-string";
import isEmpty from "lodash/isEmpty";
import { State } from "../interface";

const prefix = "v1";
const API_CONFIG = {
	BASE_URL: `${process.env.REACT_APP_BASE_URL}`,
	PATH: {
		AWS_CREDS: `${prefix}/temp-credentials`,
		USER_PROFILE: `${prefix}/user/myprofile`,
		LOGIN: `${prefix}/user/sign-in`,
		SIGNUP: `${prefix}/user/sign-up`,
		BUSINESS_INFO: `${prefix}/user/profile`,
		LOGOUT: `${prefix}/user/sign-out`,
		FORGET_PASSWORD: `${prefix}/user/forget-password`,
		RESET_PASSWORD: `${prefix}/user/confirm-password`,
		FEEDBACK_FORM_DEFAULT_CATEGORIES: `${prefix}/user/feedback/default-categories`,
		FEEDBACK_FORM: `${prefix}/user/feedback/feedback-form`,
		FEEDBACK_FORMS: `${prefix}/user/feedback/feedback-forms`,
		FEEDBACK_URL: `${prefix}/feedback/url`,
		TEAMMATES: `${prefix}/user/reps`,
		TEAMMATES_UPDATE: `${prefix}/user/reps/update`,
		TEAMMATES_SKIP: `${prefix}/user/reps/skip`,
		TEAMMATES_SUSPEND: `${prefix}/user/reps/suspend`,
		COMMENT_LISTING: `${prefix}/feedback`,
		COMMENT_COUNT: `${prefix}/feedback/needreplycount`,
		CHAT_REPLIES: `${prefix}/feedback/replies`,
		SEND_REPLY: `${prefix}/feedback/reply`,
		SNOOZE_STATUS: `${prefix}/feedback/snooze`,
		CHANGE_PASSWORD: `${prefix}/user/changepassword`,
		MARK_AS_REPLIED: `${prefix}/feedback/markasreplied`,
		DASHBOARD: `${prefix}/user/dashboard`,
	},
};

const getUrl = (url: string, params: any = {}): string => {
	let urlString = `${API_CONFIG.BASE_URL}/api/${url}`;
	if (params && !isEmpty(params)) {
		urlString += `?${queryString.stringify(params)}`;
	}
	return urlString;
};

const STATIC_VALUES = {
	PROFILE_BUCKET: "bizzping-upload",
};

const getStep = (state: State) => {
	const { auth, feedbackForm, teammates } = state;
	if (
		auth.step === null &&
		feedbackForm.step === null &&
		teammates.step === null
	) {
		return null;
	}

	return Math.max(
		auth.step || 0,
		feedbackForm.step || 0,
		teammates.step || 0
	);
};

export { API_CONFIG, getUrl, getStep, STATIC_VALUES };

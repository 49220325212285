import {
	Action,
	NotificationStateProps,
	State,
} from "../../../../../shared/interface";
import React from "react";
import SignupBusinessInfoForm from "./signupBusinessInfoForm";
import Utility from "../../../../../shared/util/utility";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import {
	AuthMapStateProps,
	UserSignupBusinessInfoParams,
} from "../../../interface/login.interface";
import LoginActions from "../../../store/login.action";
import AwsService from "../../../../../shared/services/aws.service";
import { STEPS } from "../../../helpers";
import { Redirect, RouteComponentProps } from "react-router";
import * as H from "history";
import actionTypes from "../../../../../store/action-types";
import { getStep } from "../../../../../shared/constants/constants";

interface DispatchProps extends NotificationStateProps {
	disableGoNext: () => void;
	showLoader: () => void;
	hideLoader: () => void;
	fetchProfile: () => void;
	onSignupBusinessInfo: (
		payload: UserSignupBusinessInfoParams,
		history?: H.History
	) => void;
	onUpdateBusinessInfo: (
		payload: UserSignupBusinessInfoParams,
		history?: H.History
	) => void;
}

interface UIState {
	loading: boolean;
	step: number | null;
	goNext?: boolean;
}

class BusinessInfo extends React.Component<
	AuthMapStateProps & DispatchProps & RouteComponentProps,
	UIState
> {
	state: UIState = {
		loading: false,
		step: this.props.step,
	};

	componentDidMount(): void {
		if (this.props.step && this.props.step < STEPS.BUSINESS_SIGNUP) {
			this.props.history.replace(`/signup/${this.props.step}`);
			return;
		}
		this.props.fetchProfile();
	}

	render() {
		if (this.props.userLoginData.goNext) {
			this.props.disableGoNext();
			return <Redirect to={`/signup/${this.props.step}`} />;
		}
		return (
			<SignupBusinessInfoForm
				step={this.props.step}
				initialValues={this.initialValues()}
				loadingImage={this.state.loading}
				loading={this.props.loading}
				onSubmit={this.onSubmit}
			/>
		);
	}

	initialValues = () => {
		const data = {
			logoUrl: {} as any,
			businessName: "",
			address: "",
			locality: "",
			webaddress: "",
			description: "",
		};

		if (this.props.step === STEPS.BUSINESS_SIGNUP) {
			return data;
		}
		const { user } = this.props.userLoginData;
		if (!user || !user.profile) {
			return data;
		}

		return {
			logoUrl: {
				base64: user.profile.logoUrl,
				fileData: {},
			} as any,
			businessName: user.profile.businessName,
			address: user.profile.address,
			locality: user.profile.locality || "",
			webaddress: user.profile.webaddress,
			description: user.profile.description,
		};
	};

	onSubmit = (data: any) => {
		const file: File = data.logoUrl.fileData;
		// const uploadToS3 = (this.props.step === STEPS.BUSINESS_SIGNUP || file.name);
		const uploadToS3 = file && file.name;
		// set aws creds loading = true
		// this.setState(prevState => ({...prevState, loading: true}));

		this.props.showLoader();

		let promise = new Promise((resolve) => resolve("step2"));
		if (uploadToS3) {
			promise = LoginActions.getAwsCredentials();
		}
		promise
			.then((creds: any) => {
				if (uploadToS3) {
					// upload file to s3
					// update aws config
					AwsService.updateAwsConfig(creds);
					promise = AwsService.uploadImage({
						Key: `${Math.random().toString().substr(3)}_${
							file.name
						}`,
						ContentType: file.type,
						Body: file,
						Bucket: creds.bucket,
					});
				}

				promise
					.then((url) => {
						if (!uploadToS3) {
							url = this.props.userLoginData.user.profile.logoUrl;
						}
						this.props.hideLoader();
						// set loading = false, and start API call to add business profile
						this.setState((prevState) => ({
							...prevState,
							loading: false,
						}));
						if (this.props.step === STEPS.BUSINESS_SIGNUP) {
							this.props.onSignupBusinessInfo(
								{ ...data, logoUrl: url },
								this.props.history
							);
						} else {
							this.props.onUpdateBusinessInfo(
								{ ...data, logoUrl: url },
								this.props.history
							);
						}
					})
					.catch((e) => {
						this.props.hideLoader();
						// this.setState(prevState => ({...prevState, loading: false}));
						this.props.addNotification(e.message, "error");
					});
			})
			.catch((e) => {
				this.props.hideLoader();
				// this.setState(prevState => ({...prevState, loading: false}));
				this.props.addNotification(e.message, "error");
			});
	};
}

const loadingSelector = Utility.createLoadingSelector([
	"SIGNUP_BUSINESS_INFO",
	"UPDATE_SIGNUP_BUSINESS_INFO",
]);

const mapStateToProps = (state: State): AuthMapStateProps => ({
	...state.auth,
	step: getStep(state),
	loading: loadingSelector(state),
});
const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	...Utility.getNotificationProps(dispatch),
	disableGoNext: () =>
		dispatch(Utility.createAction(actionTypes.DISABLE_GO_NEXT)),
	showLoader: () =>
		dispatch(Utility.createAction(actionTypes.SIGNUP_BUSINESS_INFO_INIT)),
	hideLoader: () =>
		dispatch(Utility.createAction(actionTypes.SIGNUP_BUSINESS_INFO_FAIL)),
	fetchProfile: () => dispatch(LoginActions.fetchProfile()),
	onSignupBusinessInfo: (payload, history?) =>
		dispatch(LoginActions.signUpBusinessInfo(payload, history)),
	onUpdateBusinessInfo: (payload, history?) =>
		dispatch(LoginActions.updateBusinessInfo(payload, history)),
});

export default connect<AuthMapStateProps, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(BusinessInfo);

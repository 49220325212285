import React from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";

import AuthService from "shared/services/auth.service";

import { Input } from "shared/components/form/inputTypes";

import {
	AuthMapStateProps,
	LoginDispatchProps,
} from "../interface/login.interface";
import { RouteComponentProps } from "react-router-dom";
import * as schema from "shared/constants/validation-schema";
import Button from "shared/components/form/button";
import FormSpinner from "shared/components/spinner/formSpinner";
import { STEPS } from "../helpers";

interface Props
	extends LoginDispatchProps,
		AuthMapStateProps,
		RouteComponentProps {}

const LoginComponent: React.FC<Props> = (props) => {
	const { location } = props;
	const { state }: any = location;
	const isLoggedIn = AuthService.checkLogin();
	if (isLoggedIn.auth) {
		if (state) {
			localStorage.setItem("redirectUrl", JSON.stringify(state));
		}
		window.location.replace("/");
		return null;
	}
	// redirect to signup if user signup is not completed (i.e., step is less than 4)
	if (
		isLoggedIn.step &&
		(isLoggedIn.step as number) <= STEPS.REPRESENTATIVE &&
		!isLoggedIn.auth
	) {
		window.location.replace(`/signup/${isLoggedIn.step}`);
		return null;
	}
	return (
		<div className="container">
			<div className="flex flex-wrap">
				<div className="form-white-block w-full sm:w-1/2 md:w-1/7">
					<h1 className="form-title">Welcome back,</h1>
					<Formik
						initialValues={{ email: "", password: "" }}
						validateOnBlur={true}
						validateOnChange={true}
						onSubmit={props.onLogin}
						validationSchema={schema.login}
					>
						{({ handleSubmit, errors, touched }) => {
							const disabled =
								props.loading ||
								Object.keys(errors).length > 0 ||
								Object.keys(touched).length === 0;
							return (
								<form onSubmit={handleSubmit}>
									<div className="form-group">
										<label htmlFor="email">
											Your Email Address{" "}
											<span className="text-red">*</span>
										</label>
										<Input
											placeholder="name@yourbusiness.com"
											type="email"
											name="email"
											autoComplete="email"
											showErrorMessage
										/>
									</div>

									<div className="form-group mb-4">
										<label htmlFor="password">
											Password{" "}
											<span className="text-red">*</span>
										</label>
										<Input
											placeholder="Enter password"
											type="password"
											name="password"
											autoComplete="current-password"
											showErrorMessage
										/>
									</div>

									<p className="mb-8">
										<Link
											className="text-green hover:text-seagreen font-medium text-sm"
											to="/forget-password"
										>
											Forgot password?
										</Link>
									</p>

									<Button
										className={`btn btn-green btn-large mb-12 ${
											disabled ? "disable" : ""
										}`}
										loading={props.loading}
										disabled={disabled}
										type="submit"
									>
										Log In
									</Button>
								</form>
							);
						}}
					</Formik>
					<FormSpinner isSubmitting={props.loading} />
					<p className="text-sm text-gray-100 font-semibold">
						Don’t have an account? &nbsp;
						<Link
							to="/signup"
							className="text-green hover:text-seagreen block xs:inline-block"
						>
							Sign Up for Business Account
						</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

export default LoginComponent;

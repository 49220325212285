import {ThunkDispatch} from "redux-thunk";
import {Action} from "../../../../shared/interface";
import Utility from "../../../../shared/util/utility";
import actionTypes from "../../../../store/action-types";
import HttpService from "../../../../shared/services/http.service";
import {API_CONFIG} from "../../../../shared/constants/constants";
import {SanToCamelCase} from "san-camel/dist";
import authService from "../../../../shared/services/auth.service";
import {
	FeedbackFormParams,
	IFeedbackFormResponse,
	UpdateFeedbackFormParams
} from "../../interface/feedback-form.interface";
import {next, STEPS} from "../../helpers";
import * as H from "history";

const fetchDefaultCategories = () => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(Utility.createAction(actionTypes.FEEDBACK_FORM_CATEGORIES_INIT));
		HttpService.get(API_CONFIG.PATH.FEEDBACK_FORM_DEFAULT_CATEGORIES).then(response => {
			dispatch(Utility.createAction(actionTypes.FEEDBACK_FORM_CATEGORIES_SUCCESS, response));
		}).catch(e => {
			dispatch(Utility.createAction(actionTypes.FEEDBACK_FORM_CATEGORIES_FAIL, {message: e.message}));
		});
	};
};

const fetchSavedFormDetails = (id?: string) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		if (!id) return;

		dispatch(Utility.createAction(actionTypes.FEEDBACK_SAVED_FORM_INIT));
		HttpService.get(`${API_CONFIG.PATH.FEEDBACK_FORM}/${id}`).then(response => {
			authService.setFeedbackFormData(response);
			dispatch(Utility.createAction(actionTypes.FEEDBACK_SAVED_FORM_SUCCESS, response));
		}).catch(e => {
			dispatch(Utility.createAction(actionTypes.FEEDBACK_SAVED_FORM_FAIL, {message: e.message}));
		});
	};
};

const fetchFormUsingSlug = (slug?: string) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		if (!slug) return;

		dispatch(Utility.createAction(actionTypes.FEEDBACK_FORM_SLUG_INIT));
		HttpService.get(API_CONFIG.PATH.FEEDBACK_URL, {slug}).then(response => {
			const data = {
				...response.feedbackForm,
				businessUser: response.businessUser
			} as IFeedbackFormResponse;

			authService.setFeedbackFormData(data);
			dispatch(Utility.createAction(actionTypes.FEEDBACK_FORM_SLUG_SUCCESS, data));
		}).catch(e => {
			dispatch(Utility.createAction(actionTypes.FEEDBACK_FORM_SLUG_FAIL, {message: e.message}));
		});
	};
};

/**
 * handle signup feedback form action
 * @param data - FeedbackFormParams etc
 * @param history
 */
const signUpFeedbackForm = (data: FeedbackFormParams, history?: H.History) => {
	data = SanToCamelCase(data);

	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(Utility.createAction(actionTypes.FEEDBACK_FORM_INIT));
		HttpService.post(API_CONFIG.PATH.FEEDBACK_FORM, data).then(response => {
			response.onDashboard = !history;
			authService.setFeedbackFormData(response, true, response.onDashboard);

			response.goNext = true;
			dispatch(Utility.createAction(actionTypes.FEEDBACK_FORM_SUCCESS, response));
			next(history, STEPS.REPRESENTATIVE);
		}).catch(e => {
			dispatch(Utility.createAction(actionTypes.FEEDBACK_FORM_FAIL, {message: e.message}));
		});
	};
};

/**
 * handle update signup feedback form action
 * @param data - UpdateFeedbackFormParams etc
 * @param history
 */
const updateFeedbackForm = (data: UpdateFeedbackFormParams, history?: H.History) => {
	data = SanToCamelCase(data);

	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(Utility.createAction(actionTypes.UPDATE_FEEDBACK_FORM_INIT));
		HttpService.put(API_CONFIG.PATH.FEEDBACK_FORM, data).then(response => {
			response.onDashboard = !history;
			authService.setFeedbackFormData(response, true, response.onDashboard);

			response.goNext = true;
			dispatch(Utility.createAction(actionTypes.UPDATE_FEEDBACK_FORM_SUCCESS, response));
			next(history, STEPS.REPRESENTATIVE);
		}).catch(e => {
			dispatch(Utility.createAction(actionTypes.UPDATE_FEEDBACK_FORM_FAIL, {message: e.message}));
		});
	};
};

const FeedbackFormActions = {
	signUpFeedbackForm,
	fetchDefaultCategories,
	fetchSavedFormDetails,
	updateFeedbackForm,
	fetchFormUsingSlug
};

export default FeedbackFormActions;

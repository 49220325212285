import {Action, NotificationStateProps, State} from "../../../../../shared/interface";
import React from "react";
import Utility from "../../../../../shared/util/utility";
import {ThunkDispatch} from "redux-thunk";
import {connect} from "react-redux";
import {AuthMapStateProps, UserSignupBasicInfoParams} from "../../../interface/login.interface";
import LoginActions from "../../../store/login.action";
import SignupBasicInfoForm from "./signupBasicInfoForm";
import {STEPS} from "../../../helpers";
import {Redirect, RouteComponentProps} from "react-router";
import * as H from "history";
import {getStep} from "../../../../../shared/constants/constants";
import actionTypes from "../../../../../store/action-types";

interface DispatchProps extends NotificationStateProps {
	disableGoNext: () => void;
	onSignupBasicInfo: (payload: UserSignupBasicInfoParams, history?: H.History) => void;
}

class BasicInfo extends React.Component<AuthMapStateProps & DispatchProps & RouteComponentProps, {}> {
	render() {
		if (this.props.userLoginData.goNext) {
			this.props.disableGoNext();
			return <Redirect to={`/signup/${this.props.step}`}/>;
		}
		return (
			<SignupBasicInfoForm
				step={this.props.step}
				initialValues={this.initialValues()}
				loading={this.props.loading}
				onSubmit={this.onSubmit}
			/>
		);
	}

	onSubmit = (data: UserSignupBasicInfoParams) => {
		this.props.onSignupBasicInfo(data, this.props.history);
	};

	initialValues = () => {
		if (this.props.step === STEPS.BASIC_SIGNUP) {
			return {
				email: "",
				password: "",
				name: "",
				mobileNo: ""
			};
		}
		return {
			email: this.props.userLoginData.user.email,
			password: "********",
			name: this.props.userLoginData.user.name,
			mobileNo: this.props.userLoginData.user.mobileNo,
		};
	};
}

const loadingSelector = Utility.createLoadingSelector(["SIGNUP_BASIC_INFO"]);

const mapStateToProps = (state: State): AuthMapStateProps => ({
	...state.auth,
	step: getStep(state),
	loading: loadingSelector(state)
});
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, Action>): DispatchProps => ({
	...Utility.getNotificationProps(dispatch),
	disableGoNext: () => dispatch(Utility.createAction(actionTypes.DISABLE_GO_NEXT)),
	onSignupBasicInfo: (payload, history) => dispatch(LoginActions.signUpBasicInfo(payload, history))
});

export default connect<AuthMapStateProps, DispatchProps, {}, State>(mapStateToProps, mapDispatchToProps)(BasicInfo);

import {Input} from "../../../../../shared/components/form/inputTypes";
import clearIcon from "../../../../../assets/images/clear.svg";
import React from "react";

interface IProps {
	showRemoveIcon?: boolean;
	name: string;
	onClickRemove: (e: any) => void;
	setFieldValue?: (field: string, value: string) => void;
}

export const TeamMate = ({showRemoveIcon, setFieldValue, ...props}: IProps) => {
	return (
		<>
			<div className="form-group">
				<label>Name <span className="text-red">*</span></label>
				<Input type="text" name={`${props.name}.name`} placeholder="Sam Willson"
					   showErrorMessage/>

				{showRemoveIcon ? (
					<img src={clearIcon} className="clear-fields-icon border-red" alt="" title=""
						 onClick={props.onClickRemove}/>
				) : ""}
			</div>
			<div className="form-group">
				<label>Email Address <span className="text-red">*</span></label>
				<Input type="text" name={`${props.name}.email`} placeholder="samwillson@xyz.com"
					   showErrorMessage/>
			</div>
			<div className="form-group mb-3">
				<label>Mobile Number <span className="text-red">*</span></label>
				<Input type="tel" name={`${props.name}.mobileNo`} placeholder="(555)-555-5555"
					   showErrorMessage setFieldValue={setFieldValue}/>
			</div>
		</>
	);
};

import {ThunkDispatch} from "redux-thunk";

import actionTypes from "store/action-types";
import AuthService from "shared/services/auth.service";
import HttpService from "shared/services/http.service";
import {API_CONFIG} from "shared/constants/constants";
import Utility from "shared/util/utility";
import {Action, AwsTempCreds} from "shared/interface";
import {
	LoginResponse,
	UserLoginParams,
	UserSignupBasicInfoParams,
	UserSignupBusinessInfoParams
} from "../interface/login.interface";
import {next, STEPS} from "../helpers";
import * as H from "history";

/**
 * handle login action
 * @param data - username & password
 */
const login = (data: UserLoginParams) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(Utility.createAction(actionTypes.AUTH_INIT));
		HttpService.post(API_CONFIG.PATH.LOGIN, {}, {}, {
			headers: {
				authorization: `Bearer ${new Buffer(`${data.email}:${data.password}`).toString("base64")}`
			}
		}).then((response: LoginResponse) => {
			AuthService.setAuthData(response);
			dispatch(Utility.createAction(actionTypes.AUTH_SUCCESS, response));
		}).catch(e => {
			dispatch(Utility.createAction(actionTypes.AUTH_FAIL, {message: e.message}));
		});
	};
};

/**
 * handle signup action
 * @param data - name, email, contact-number and password
 * @param history
 */
const signUpBasicInfo = (data: UserSignupBasicInfoParams, history?: H.History) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(Utility.createAction(actionTypes.SIGNUP_BASIC_INFO_INIT));
		HttpService.post(API_CONFIG.PATH.SIGNUP, data).then((response: LoginResponse) => {
			AuthService.setAuthData(response);
			dispatch(Utility.createAction(actionTypes.SIGNUP_BASIC_INFO_SUCCESS, {
				...response,
				goNext: true
			}));
			next(history, STEPS.BUSINESS_SIGNUP);
		}).catch(e => {
			dispatch(Utility.createAction(actionTypes.SIGNUP_BASIC_INFO_FAIL, {message: e.message}));
		});
	};
};

/**
 * handle signup business info action
 * @param data - business name, addess, website, logo etc
 * @param history
 */
const signUpBusinessInfo = (data: UserSignupBusinessInfoParams, history?: H.History) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(Utility.createAction(actionTypes.SIGNUP_BUSINESS_INFO_INIT));
		HttpService.post(API_CONFIG.PATH.BUSINESS_INFO, data).then(response => {
			const authData: LoginResponse = AuthService.getAuthData() as LoginResponse;
			authData.user = {...authData.user, ...response, step: STEPS.FEEDBACK_FORM};
			AuthService.setAuthData(authData);
			dispatch(Utility.createAction(actionTypes.SIGNUP_BUSINESS_INFO_SUCCESS, {
				...authData,
				goNext: true
			}));
			next(history, STEPS.FEEDBACK_FORM);
		}).catch(e => {
			dispatch(Utility.createAction(actionTypes.SIGNUP_BUSINESS_INFO_FAIL, {message: e.message}));
		});
	};
};

/**
 * handle signup business info action
 * @param data - business name, addess, website, logo etc
 * @param history
 */
const updateBusinessInfo = (data: UserSignupBusinessInfoParams, history?: H.History) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(Utility.createAction(actionTypes.UPDATE_SIGNUP_BUSINESS_INFO_INIT));
		HttpService.put(API_CONFIG.PATH.BUSINESS_INFO, data).then(response => {
			const authData: LoginResponse = AuthService.getAuthData() as LoginResponse;
			authData.user = {...authData.user, ...response, step: STEPS.FEEDBACK_FORM};
			AuthService.setAuthData(authData);
			dispatch(Utility.createAction(actionTypes.UPDATE_SIGNUP_BUSINESS_INFO_SUCCESS, {
				...authData,
				goNext: true,
				message: "Business profile has been updated"
			}));
			next(history, STEPS.FEEDBACK_FORM);
		}).catch(e => {
			dispatch(Utility.createAction(actionTypes.UPDATE_SIGNUP_BUSINESS_INFO_FAIL, {message: e.message}));
		});
	};
};

/**
 * logout action dispatch
 */
const logout = () => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(Utility.createAction(actionTypes.AUTH_LOGOUT_INIT));

		AuthService.removeAuthData();
		window.location.replace("/login");
	};
};

const fetchProfile = () => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(Utility.createAction(actionTypes.FETCH_PROFILE_INIT));
		HttpService.get(API_CONFIG.PATH.BUSINESS_INFO).then((response) => {

			const authData: LoginResponse = AuthService.getAuthData() as LoginResponse;
			authData.user = {...authData.user, profile: response};

			AuthService.setAuthData(authData);

			dispatch(Utility.createAction(actionTypes.FETCH_PROFILE_SUCCESS, authData));
		}).catch(e => {
			dispatch(Utility.createAction(actionTypes.FETCH_PROFILE_FAIL, {message: e.message}));
		});
	};
};

/**
 * fetch temporary aws credentials from the server
 */
const getAwsCredentials = (): Promise<AwsTempCreds> => {
	return new Promise((resolve, reject) => HttpService.get(API_CONFIG.PATH.AWS_CREDS).then(resolve).catch(reject));
};

const LoginActions = {
	signUpBasicInfo,
	signUpBusinessInfo,
	updateBusinessInfo,
	login,
	logout,
	getAwsCredentials,
	fetchProfile
};

export default LoginActions;

import React from "react";
import { Field } from "formik";

const getValue = (value?: string | number) => {
	if (value === undefined || value === null) {
		return "";
	}
	return value;
};

interface RenderReusableInputProps {
	props: React.ComponentProps<any>;
	component: React.ComponentType<any>;
}

const RenderReusableInput: React.FC<RenderReusableInputProps> = ({
	component: Component,
	props,
}) => {
	const fieldRender = ({ field, form }: { field: any; form: any }) => {
		return <Component {...props} field={field} form={form} />;
	};

	return <Field name={props.name}>{fieldRender}</Field>;
};

export { getValue, RenderReusableInput };

import { Link } from "react-router-dom";
import logo from "../../../../../assets/images/logo.svg";
import close from "../../../../../assets/images/close-white.svg";
import React, { useEffect, useState } from "react";
import * as serviceWorker from "../../../../../serviceWorker";
import SideNav from "./side-nav";

interface IProps {
	children?: any;
}

export const toggleClass = () => {
	const dashboardContainer = document.querySelector(
		".mobile-dashboard-header"
	);
	const mobileNav = document.querySelector(".mobile-dashboard-nav");
	const bodyOverflow = document.querySelector("body");
	if (dashboardContainer) {
		dashboardContainer.classList.toggle("dashboard-nav-open");
	}
	if (mobileNav) {
		mobileNav.classList.toggle("mobile-nav-open");
	}
	if (bodyOverflow) {
		bodyOverflow.classList.toggle("overflow-hidden");
	}
};

const DashboardLayout: React.FC = (props: IProps) => {
	const [updatesAvailable, setUpdatesAvailable] = useState(false);
	const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
		null
	);
	let refreshing = false;
	useEffect(() => {
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker.addEventListener(
				"controllerchange",
				function () {
					if (refreshing) return;
					refreshing = true;
					window.location.reload();
				}
			);
		}
	}, []);

	const reloadPage = () => {
		if (waitingWorker) {
			waitingWorker.postMessage({ type: "SKIP_WAITING" });
			setUpdatesAvailable(false);
			window.location.reload(true);
		}
	};
	const onSWUpdate = (registration: ServiceWorkerRegistration) => {
		setUpdatesAvailable(true);
		setWaitingWorker(registration.waiting);
	};

	useEffect(() => {
		serviceWorker.register({ onUpdate: onSWUpdate });
	}, []);
	useEffect(() => {
		document.body.classList.add("dashboard-body");
		return () => {
			document.body.classList.remove("dashboard-body");
		};
	}, []);

	return (
		<div className="dashboard-container">
			<div className="mobile-dashboard-header lg:hidden">
				<button
					id="dashboard-nav-toggle"
					onClick={toggleClass}
					className="navbar-toggler-icon dashboard-toggle"
				>
					<span className="dashboard-nav-toggle-icon">
						toggle menu
					</span>
				</button>
				<Link to="/">
					<img
						src={logo}
						alt="logo"
						className="logo"
						title="BizzPing"
					/>
				</Link>
				{/* <div className="notification">
					<span className="noti-dot" />
					<img src={notiIconLight} alt="" title="" />
				</div> */}
			</div>

			<SideNav />

			<main className="dashboard-content">
				<div className="dashboard-content-inner">{props.children}</div>
				{updatesAvailable && (
					<div className="app-update-toast">
						<span>
							A new version is available, click to refresh
						</span>
						<div>
							<span
								className="link-text uppercase cursor-pointer"
								onClick={reloadPage}
							>
								Refresh
							</span>
							<img
								src={close}
								onClick={() => setUpdatesAvailable(false)}
								alt=""
								className="ml-4 cursor-pointer close-icon"
							/>
						</div>
					</div>
				)}
			</main>
		</div>
	);
};

export default DashboardLayout;

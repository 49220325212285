import React from "react";

interface ISymbolProps {
	x: number;
	y: number;
	size: number;
	fill: string;
	borderWidth: number;
	borderColor: string;
}

const SymbolCircle: React.FC<ISymbolProps> = (props) => {
	const { x, y, size, fill } = props;

	return (
		<circle
			r={size / 2}
			cx={x + size / 2}
			cy={y + size / 2}
			fill={fill}
			fillOpacity={0}
			strokeWidth={2.5}
			stroke={fill}
			style={{
				pointerEvents: "none",
			}}
		/>
	);
};

export default SymbolCircle;

import React from "react";
import round from "lodash.round";
import ResponsiveBar from "./ResponsiveBar";
import {
	normalizedTickValues,
	aggregateTime,
	findAxisBottomTimeFormat,
} from "./TotalFeedback";

import cloneDeep from "lodash.clonedeep";

interface IProps {
	data: any[];
	duration: string;
}

const TotalResponseTime: React.FC<IProps> = ({ data, duration }) => {
	const clonedData = cloneDeep(data);
	const normalizedISOtime: any = {};
	const timeAdjustedRatingData: any = [];
	let tickValues: string[] = [];
	clonedData.forEach((feedback) => {
		for (let key in feedback) {
			let normalizedDuration = aggregateTime(duration, key);

			if (!normalizedISOtime[normalizedDuration]) {
				normalizedISOtime[normalizedDuration] = {
					feedbackCount: feedback[key].hours || 1,
					count: 1,
				};
				tickValues.push(normalizedDuration);
			} else {
				const previousRecord = normalizedISOtime[normalizedDuration];
				normalizedISOtime[normalizedDuration].feedbackCount =
					previousRecord.feedbackCount + (feedback[key].hours || 1);
				normalizedISOtime[normalizedDuration].count =
					previousRecord.count + 1;
			}
		}
	});
	tickValues = normalizedTickValues(tickValues);
	for (let date in normalizedISOtime) {
		timeAdjustedRatingData.push({
			date: date,
			Time: parseFloat(
				(
					normalizedISOtime[date].feedbackCount /
					normalizedISOtime[date].count
				).toFixed(1)
			),
		});
	}
	let axisBottomTimeFormat = findAxisBottomTimeFormat(duration);

	return (
		<div className="w-full lg:w-1/3 px-3">
			<div className="card bar-chart-card">
				<h4 className="chart-title mb-2">Avg Business Response Time</h4>
				<ResponsiveBar
					data={timeAdjustedRatingData}
					formatAxisBottom={axisBottomTimeFormat}
					tickValues={tickValues}
					tooltipString={"Hour(s)"}
					formatAxisLeft={(value: string) =>
						`${round(parseFloat(value), 2)} hr`
					}
					indexBy="date"
					keys={["Time"]}
				/>
			</div>
		</div>
	);
};

export default TotalResponseTime;

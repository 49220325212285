import * as H from "history";
import {SanToCamelCase} from "san-camel/dist";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "../../../../shared/interface";
import Utility from "../../../../shared/util/utility";
import actionTypes from "../../../../store/action-types";
import HttpService from "../../../../shared/services/http.service";
import {API_CONFIG} from "../../../../shared/constants/constants";
import authService from "../../../../shared/services/auth.service";
import {ITeammatesParams} from "../../interface/teammates.interface";
import {STEPS} from "../../helpers";

/**
 * handle signup teammate form action
 * @param data - ITeamMateParams etc
 * @param history
 */
const signUpTeamMateForm = (data: ITeammatesParams, history?: H.History) => {
	data = SanToCamelCase(data);

	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(Utility.createAction(actionTypes.TEAMMATE_FORM_INIT));
		HttpService.post(API_CONFIG.PATH.TEAMMATES, data).then(response => {
			if (response) {
				response = {
					...response,
					skippedMatesForm: response.skipRep || false,
					showSuccessPage: true,
					step: response.step || STEPS.REPRESENTATIVE + 1
				};
			} else {
				response = {
					showSuccessPage: true,
					step: STEPS.REPRESENTATIVE + 1,
					skippedMatesForm: false
				};
			}
			authService.setTeammatesFormData(response);
			dispatch(Utility.createAction(actionTypes.TEAMMATE_FORM_SUCCESS, response));
		}).catch(e => {
			dispatch(Utility.createAction(actionTypes.TEAMMATE_FORM_FAIL, {message: e.message}));
		});
	};
};

/**
 * handle signup teammate form action
 * @param payload
 * @param history
 */
const signUpTeamMateSkipForm = (payload: ITeammatesParams, history?: H.History) => {
	payload = SanToCamelCase(payload);

	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(Utility.createAction(actionTypes.TEAMMATE_FORM_INIT));
		HttpService.post(API_CONFIG.PATH.TEAMMATES_SKIP, payload).then(response => {
			const data = {
				...response,
				skippedMatesForm: response.skipRep,
				showSuccessPage: true,
				formUrl: ""
			};
			authService.setTeammatesFormData(data);
			dispatch(Utility.createAction(actionTypes.TEAMMATE_FORM_SUCCESS, data));
		}).catch(e => {
			dispatch(Utility.createAction(actionTypes.TEAMMATE_FORM_FAIL, {message: e.message}));
		});
	};
};

const TeamMateFormActions = {
	signUpTeamMateForm,
	signUpTeamMateSkipForm
};

export default TeamMateFormActions;

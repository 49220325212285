const actionTypes = {
	AUTH_INIT: "AUTH_INIT",
	AUTH_SUCCESS: "AUTH_SUCCESS",
	AUTH_FAIL: "AUTH_FAIL",

	SIGNUP_BASIC_INFO_INIT: "SIGNUP_BASIC_INFO_INIT",
	SIGNUP_BASIC_INFO_SUCCESS: "SIGNUP_BASIC_INFO_SUCCESS",
	SIGNUP_BASIC_INFO_FAIL: "SIGNUP_BASIC_INFO_FAIL",

	SIGNUP_BUSINESS_INFO_INIT: "SIGNUP_BUSINESS_INFO_INIT",
	SIGNUP_BUSINESS_INFO_SUCCESS: "SIGNUP_BUSINESS_INFO_SUCCESS",
	SIGNUP_BUSINESS_INFO_FAIL: "SIGNUP_BUSINESS_INFO_FAIL",

	FETCH_PROFILE_INIT: "FETCH_PROFILE_INIT",
	FETCH_PROFILE_SUCCESS: "FETCH_PROFILE_SUCCESS",
	FETCH_PROFILE_FAIL: "FETCH_PROFILE_FAIL",

	FEEDBACK_FORM_INIT: "FEEDBACK_FORM_INIT",
	FEEDBACK_FORM_SUCCESS: "FEEDBACK_FORM_SUCCESS",
	FEEDBACK_FORM_FAIL: "FEEDBACK_FORM_FAIL",

	FEEDBACK_FORM_CATEGORIES_INIT: "FEEDBACK_FORM_CATEGORIES_INIT",
	FEEDBACK_FORM_CATEGORIES_SUCCESS: "FEEDBACK_FORM_CATEGORIES_SUCCESS",
	FEEDBACK_FORM_CATEGORIES_FAIL: "FEEDBACK_FORM_CATEGORIES_FAIL",

	FEEDBACK_FORM_SLUG_INIT: "FEEDBACK_FORM_SLUG_INIT",
	FEEDBACK_FORM_SLUG_SUCCESS: "FEEDBACK_FORM_SLUG_SUCCESS",
	FEEDBACK_FORM_SLUG_FAIL: "FEEDBACK_FORM_SLUG_FAIL",

	FEEDBACK_SAVED_FORM_INIT: "FEEDBACK_SAVED_FORM_INIT",
	FEEDBACK_SAVED_FORM_SUCCESS: "FEEDBACK_SAVED_FORM_SUCCESS",
	FEEDBACK_SAVED_FORM_FAIL: "FEEDBACK_SAVED_FORM_FAIL",

	UPDATE_FEEDBACK_FORM_INIT: "UPDATE_FEEDBACK_FORM_INIT",
	UPDATE_FEEDBACK_FORM_SUCCESS: "UPDATE_FEEDBACK_FORM_SUCCESS",
	UPDATE_FEEDBACK_FORM_FAIL: "UPDATE_FEEDBACK_FORM_FAIL",

	UPDATE_SIGNUP_BUSINESS_INFO_INIT: "UPDATE_SIGNUP_BUSINESS_INFO_INIT",
	UPDATE_SIGNUP_BUSINESS_INFO_SUCCESS: "UPDATE_SIGNUP_BUSINESS_INFO_SUCCESS",
	UPDATE_SIGNUP_BUSINESS_INFO_FAIL: "UPDATE_SIGNUP_BUSINESS_INFO_FAIL",

	TEAMMATE_FORM_INIT: "TEAMMATE_FORM_INIT",
	TEAMMATE_FORM_SUCCESS: "TEAMMATE_FORM_SUCCESS",
	TEAMMATE_FORM_FAIL: "TEAMMATE_FORM_FAIL",
	TEAMMATE_FORM_NO_ACTION: "TEAMMATE_FORM_NO_ACTION",

	AUTH_LOGOUT_INIT: "AUTH_LOGOUT_INIT",
	AUTH_LOGOUT_SUCCESS: "AUTH_LOGOUT_SUCCESS",
	AUTH_LOGOUT_FAIL: "AUTH_LOGOUT_FAIL",

	NO_ACTION: "NO_ACTION",
	OPEN_MODAL: "OPEN_MODAL",
	CLOSE_MODAL: "CLOSE_MODAL",
	SET_NOTIFICATION: "SET_NOTIFICATION",
	RESET_NOTIFICATION: "RESET_NOTIFICATION",
	DISABLE_GO_NEXT: "DISABLE_GO_NEXT"

};

export default actionTypes;

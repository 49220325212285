import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Redirect, RouteComponentProps } from "react-router-dom";

import {
	Action,
	IFeedbackFormState,
	ITeamMatesFormState,
	LoaderState,
	NotificationStateProps,
	State,
} from "shared/interface";
import Utility from "shared/util/utility";
import AuthService from "shared/services/auth.service";

import { AuthMapStateProps } from "../interface/login.interface";
import FormSpinner from "shared/components/spinner/formSpinner";
import BasicInfo from "../component/signup/basicInfo";
import BusinessInfo from "../component/signup/businessInfo";
import FeedbackForm from "../component/signup/feedback";
import { Route, Switch } from "react-router";
import { STEPS } from "../helpers";
import RepresentativeForm from "../component/signup/representative";
import { getStep } from "../../../shared/constants/constants";

interface DispatchProps extends NotificationStateProps {}

interface UIState {
	step: number | null;
}

interface IProps extends AuthMapStateProps {
	feedback: IFeedbackFormState;
	teammate: ITeamMatesFormState;
}

class Signup extends React.Component<
	DispatchProps & IProps & RouteComponentProps,
	UIState
> {
	state: UIState = {
		step: this.props.step,
	};

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		const step = parseInt(nextProps.match.params.step || nextProps.step);
		return { step };
	}

	componentDidMount(): void {
		let { step } = this.props.match.params as any;
		step = parseInt(step);

		const isLoggedIn = AuthService.checkLogin();

		if (
			this.props.step !== null &&
			step !== null &&
			step > this.props.step
		) {
			return this.props.history.replace(`/signup/${this.props.step}`);
		}

		if (step === null && !isLoggedIn.auth) {
			this.props.history.replace(`/signup/${STEPS.BASIC_SIGNUP}`);
		}
	}

	render() {
		const isLoggedIn = AuthService.checkLogin();

		if (
			isLoggedIn.auth &&
			this.state.step === null &&
			!this.props.teammate.showSuccessPage
		) {
			return <Redirect to="/dashboard" />;
		}

		const hideSteps =
			this.props.teammate.step === null ||
			this.props.teammate.step > STEPS.REPRESENTATIVE ||
			this.props.teammate.skippedMatesForm;

		return (
			<div className="container">
				<div className="flex flex-wrap">
					<div
						className={`form-white-block w-full ${
							hideSteps ? "" : "sm:w-1/2 md:w-1/7"
						}`}
					>
						<Switch>
							<Route
								component={BasicInfo}
								path={`/signup/${STEPS.BASIC_SIGNUP}`}
								exact
							/>
							<Route
								component={BusinessInfo}
								path={`/signup/${STEPS.BUSINESS_SIGNUP}`}
								exact
							/>
							<Route
								component={FeedbackForm}
								path={`/signup/${STEPS.FEEDBACK_FORM}`}
								exact
							/>

							<Route
								component={RepresentativeForm}
								path={`/signup/${STEPS.REPRESENTATIVE}`}
								exact
							/>

							<Redirect to={`/signup/${this.state.step}`} />
						</Switch>

						<FormSpinner isSubmitting={this.props.loading} />
					</div>
					{!hideSteps ? (
						<div className="form-step-block w-full sm:w-1/2 md:w-1/5">
							<ul className="form-steps">
								<li
									onClick={this.onStepClick(
										STEPS.BASIC_SIGNUP
									)}
									className={this.getStepClass(
										STEPS.BASIC_SIGNUP
									)}
								>
									<span className="form-steps-label">
										User Information
									</span>
								</li>
								<li
									onClick={this.onStepClick(
										STEPS.BUSINESS_SIGNUP
									)}
									className={this.getStepClass(
										STEPS.BUSINESS_SIGNUP
									)}
								>
									<span className="form-steps-label">
										Business Information
									</span>
								</li>
								<li
									onClick={this.onStepClick(
										STEPS.FEEDBACK_FORM
									)}
									className={this.getStepClass(
										STEPS.FEEDBACK_FORM
									)}
								>
									<span className="form-steps-label">
										Setup Feedback Form
									</span>
								</li>
								<li
									onClick={this.onStepClick(
										STEPS.REPRESENTATIVE
									)}
									className={this.getStepClass(
										STEPS.REPRESENTATIVE
									)}
								>
									<span className="form-steps-label">
										Add Representatives
									</span>
								</li>
							</ul>
						</div>
					) : (
						""
					)}
				</div>
			</div>
		);
	}

	onStepClick = (step: number) => (e: any) => {
		if (this.props.step === null) {
			return;
		}
		if (step <= this.props.step && step !== STEPS.BASIC_SIGNUP) {
			return this.props.history.push(`/signup/${step}`);
		} else if (step >= this.props.step) {
			// dont skip the current step so do nothing here
		}
	};

	getStepClass = (step: number) => {
		let className = "";
		if (this.state.step === step) {
			className = "active";
		}
		if (this.state.step && this.state.step > step) {
			className += " completed";
		}
		return className;
	};
}

const loadingSelector = Utility.createLoadingSelector([
	"SIGNUP_BASIC_INFO",
	"SIGNUP_BUSINESS_INFO",
	"UPDATE_SIGNUP_BUSINESS_INFO",
	"FEEDBACK_FORM",
	"FEEDBACK_SAVED_FORM",
	"UPDATE_FEEDBACK_FORM",
	"TEAMMATE_FORM",
]);

const mapStateToProps = (state: State): IProps => ({
	...state.auth,
	step: getStep(state),
	feedback: state.feedbackForm,
	teammate: state.teammates,
	loading: loadingSelector(state),
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	...Utility.getNotificationProps(dispatch),
});

export default connect<LoaderState, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(Signup);

import React, {useEffect} from "react";
import * as schema from "../../../../../shared/constants/validation-schema";
import {FieldArray, FieldArrayRenderProps, Formik} from "formik";
import Button from "../../../../../shared/components/form/button";
import {ITeammate, ITeammatesParams} from "../../../interface/teammates.interface";
import {TeamMate} from "./teammateForm";
import {STEPS} from "../../../helpers";
import {Link} from "react-router-dom";
import separatorIcon from "../../../../../assets/images/separator-dots.svg";
import * as H from "history";

interface Props {
	step: number | null;
	loading: boolean;
	initialValues: any;
	onSubmit: (payload: ITeammatesParams) => void;
	skipTeammates: (e?: any) => void;
	history: H.History;
}

const addTeammate = ({push}: any) => (e: any) => push({});
const removeTeammate = ({remove}: any, idx: number) => (e: any) => {
	remove(idx);
};

const SignupRepresentativeForm: React.FC<Props> = props => {

	useEffect(() => {
		const keyDownListener = (e: any) => {
			if (e.keyIdentifier === "U+000A" || e.key === "Enter" || e.keyCode === 13) {
				e.preventDefault();
				return false;
			}
		};

		window.addEventListener("keydown", keyDownListener, true);
		return () => window.removeEventListener("keydown", keyDownListener);
	});

	return (
		<Formik
			initialValues={props.initialValues}
			validateOnBlur={true}
			validateOnChange={true}
			onSubmit={props.onSubmit}
			validationSchema={schema.signupTeammateForm}
		>
			{({handleSubmit, setFieldValue, values, errors, touched}) => {
				const disabled = (props.loading || Object.keys(errors).length > 0 || Object.keys(touched).length === 0 || !values.reps.length);

				return (
					<div className="container">
						<div className="flex flex-wrap">
							<div>
								<form onSubmit={handleSubmit}>
									<h6 className="form-step-label">Step {STEPS.REPRESENTATIVE + 1} of
										4</h6>
									<h1 className="form-title mb-0 leading-tight">Add team
										members</h1>
									<h4 className="team-member-title-small">
										Add the team members who will be receiving customer comments
									</h4>

									<FieldArray name="reps">
										{(arrayHelpers: FieldArrayRenderProps) => {
											return (
												<>
													{values.reps && values.reps.map((rep: ITeammate, idx: number) => {
														return (
															<React.Fragment key={`reps[${idx}]`}>
																<TeamMate
																	key={`reps[${idx}]`}
																	name={`reps[${idx}]`}
																	showRemoveIcon
																	onClickRemove={removeTeammate(arrayHelpers, idx)}
																	setFieldValue={setFieldValue}
																/>
																<div
																	className="flex justify-center mt-8 mb-4">
																	<img src={separatorIcon} alt=""
																		 title=""/>
																</div>
															</React.Fragment>
														);
													})}
													<p className="mb-12">
														<Button
															type="button"
															onClick={addTeammate(arrayHelpers)}
															className="text-green hover:text-seagreen text-sm font-medium">
															+Add another team member
														</Button>
													</p>
													<div
														className="flex flex-wrap mb-4 items-center">
														<div
															className="flex content-between justify-around flex-wrap w-full">
															<Button
																className='btn bg-gray-400 btn-large mb-4'
																loading={props.loading}
																onClick={() => props.history.push(`/signup/${STEPS.FEEDBACK_FORM}`)}
																type='button'
															>
																<i className="fa fa-arrow-left"/> Back
															</Button>

															<Button
																className={`btn btn-green btn-large mb-4 ${disabled ? "disable" : ""}`}
																loading={props.loading}
																disabled={disabled}
																type='submit'
															>
																Submit
															</Button>
														</div>

														<Link onClick={props.skipTeammates} to="#"
															  className="skip-for-now-link ml-0">Skip
															this for now</Link>
													</div>
													<p className="text-xs text-gray-100 font-medium">
														<span
															className="text-red">*</span> Indicates
														required field.</p>
												</>
											);
										}}
									</FieldArray>
								</form>
							</div>
						</div>
					</div>
				);
			}}
		</Formik>
	);
};

export default SignupRepresentativeForm;

import React from "react";
import format from "date-fns/format";
import { ResponsiveBar } from "@nivo/bar";
import CustomSymbol from "./CustomSymbol";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

interface IProps {
	data: any[];
	indexBy: string;
	keys: string[];
	tooltipString: string;
	formatAxisLeft?: any;
	formatAxisBottom?: string;
	tickValues?: string[];
}

const MyResponsiveBar: React.FC<IProps> = ({
	data,
	keys,
	indexBy,
	tooltipString,
	tickValues,
	formatAxisLeft = (value: any) => {
		if (parseFloat(value) === Math.floor(parseFloat(value))) {
			return value;
		} else {
			return false;
		}
	},
	formatAxisBottom,
}) => (
	<ResponsiveBar
		data={data}
		keys={keys}
		indexBy={indexBy}
		margin={{ top: 50, right: 0, bottom: 40, left: 40 }}
		padding={0.8}
		colors={["#0ac15d", "#2684ff"]}
		borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
		axisTop={null}
		axisRight={null}
		axisBottom={{
			tickSize: 0,
			tickPadding: 5,
			tickValues: tickValues,
			tickRotation: 0,
			legendPosition: "middle",
			legendOffset: 32,
			format: (value: any) =>
				format(new Date(value), formatAxisBottom || "MMM dd"),
		}}
		enableLabel={false}
		axisLeft={{
			tickSize: 0,
			tickPadding: 5,
			tickRotation: 0,
			tickValues: 5,
			format: formatAxisLeft,
			legendPosition: "middle",
			legendOffset: -40,
		}}
		legends={
			keys.length > 1
				? [
						{
							dataFrom: "keys",
							anchor: "top-left",
							direction: "row",
							justify: false,
							translateX: -34,
							translateY: -50,
							itemsSpacing: 0,
							itemDirection: "left-to-right",
							itemWidth: 100,
							itemHeight: 20,
							itemOpacity: 0.75,
							symbolSize: 8,
							symbolShape: CustomSymbol,
							symbolBorderColor: "rgba(0, 0, 0, .5)",
							itemTextColor: "#616161",
						},
				  ]
				: []
		}
		tooltip={(props) => {
			const { indexValue } = props;
			const indexQuantity = props.data[props.id];
			return (
				<div className="bar-tooltip">
					<div className="chart-comment-tooltip">
						{indexQuantity} {tooltipString}
					</div>
					<div className="chart-date-tooltip">
						{format(new Date(indexValue), "dd MMM, yyyy")}
					</div>
				</div>
			);
		}}
		theme={{
			grid: {
				line: {
					stroke: "#f5f5f5",
					strokeWidth: 1,
				},
			},
			legends: {
				text: {
					fontSize: 12,
					fontFamily: "Montserrat",
				},
			},
			axis: {
				ticks: {
					text: {
						fontSize: 12,
						fontFamily: "Montserrat",
						fill: "#616161",
					},
				},
			},
			tooltip: {
				container: {
					background: "#424242",
					color: "#fff",
					fontSize: "12px",
					fontFamily: "Montserrat",
					borderRadius: "6px",
					boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1)",
					padding: "4px 8px",
				},
				basic: {
					display: "flex",
					alignItems: "center",
				},
				chip: {
					marginRight: 7,
				},
				table: {},
				tableCell: {
					padding: "8px",
				},
			},
		}}
		animate={true}
		motionStiffness={90}
		motionDamping={15}
	/>
);

export default MyResponsiveBar;

import httpService from "../shared/services/http.service";
import { API_CONFIG } from "shared/constants/constants";

export interface CommentQueryInterface {
	pageNumber: number;
	pageSize?: number;
	filterStatus?: boolean;
}
interface ChatQueryInterface {
	pageNumber?: number;
	pageSize?: number;
	feedbackId: string;
}

interface SendReplyInterface {
	feedbackId: string;
	reply: string;
}

export enum CommentStatus {
	needReply = "Need Reply",
	replyNotRequired = "Reply not required",
	replied = "Replied",
	snoozed = "Snoozed",
}

enum SnoozeStatus {
	off = "OFF",
	on = "ON",
}
export interface CommentShape {
	averageStars: string;
	ticket: string;
	customerEmail: string | null;
	customerName: string | null;
	feedback: [];
	feedbackFormId: string;
	feedbackTimestamp: string;
	markPrivate: boolean;
	message: string;
	overallStars: number | null;
	phoneNumber: string | null;
	replies: [];
	status: number;
	statusText: CommentStatus;
	updatedTimestamp: string;
	userId: string;
	snooze_status?: SnoozeStatus;
	_id: string;
}
interface CommentResponse {
	totalFeedbackCount: number;
	feedbacks: CommentShape[];
}

export enum UserType {
	BUSINESS_USER = "BUSINESS_USER",
	REPRESENTATIVE = "REPRESENTATIVE",
	CUSTOMER = "CUSTOMER",
}

export interface ChatReply {
	textBody: string;
	replyTimestamp: string;
	repliedBy: string;
	userTpe: UserType;
}

export const fetchComments = async (
	key: string,
	_params: CommentQueryInterface
) => {
	const { pageNumber, pageSize = 10, filterStatus = false } = _params;
	const response: CommentResponse = await httpService.get(
		API_CONFIG.PATH.COMMENT_LISTING,
		{
			pageNumber,
			pageSize,
			filterStatus,
		}
	);
	return response;
};

export const fetchChatReplies = async (
	key: boolean | string,
	_params: ChatQueryInterface
) => {
	const { pageNumber = 0, pageSize = 50, feedbackId } = _params;
	const response: CommentShape = await httpService.get(
		API_CONFIG.PATH.CHAT_REPLIES,
		{
			pageNumber,
			pageSize,
			feedbackId,
		}
	);
	return response;
};

export const fetchCommentCount = async () => {
	const response: {
		needReplyCount: number;
	} = await httpService.get(API_CONFIG.PATH.COMMENT_COUNT);
	return response;
};

export const markFeedbackAsReplied = async (feedbackId: string) => {
	const response: string = await httpService.post(
		API_CONFIG.PATH.MARK_AS_REPLIED,
		{},
		{
			feedbackId,
		}
	);
	return response;
};

export const changeSnoozeStatus = async ({
	feedbackId,
	currentSnoozeStatus,
}: {
	feedbackId: string;
	currentSnoozeStatus: SnoozeStatus;
}) => {
	const snooze_status = currentSnoozeStatus === "OFF" ? "ON" : "OFF";
	const response = await httpService.put(
		API_CONFIG.PATH.SNOOZE_STATUS,
		{},
		{
			feedbackId,
			snooze_status,
		}
	);
	return response;
};

export const sendReplyToCustomer = async (variables: SendReplyInterface) => {
	const response = await httpService.post(
		API_CONFIG.PATH.SEND_REPLY,
		variables
	);
	return response;
};

/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import successImg from "assets/images/success-account.png";

// const preventD = (e: any) => e.preventDefault();

interface IProps {
	successSignup: (e?: any) => void;
	formLink: string;
	businessName: string;
}

const Success: React.FC<IProps> = (props: IProps) => {
	const href = props.formLink.includes("://")
		? props.formLink
		: `https://${props.formLink}`;
	return (
		<div className="signup-success mt-0">
			<img
				src={successImg}
				className="success-img animated heartBeat"
				alt=""
				title=""
			/>
			<h4 className="font-extrabold text-large text-black mb-6 mt-1">
				Voila!
			</h4>
			<p>
				Your account has been created successfully and Feedback form is
				ready to share with your consumer with below link.
			</p>
			<a
				target="_blank"
				href={href}
				className="text-green hover:text-seagreen font-medium break-all w-full"
			>
				{props.formLink.includes("://")
					? props.formLink.split("://")[1]
					: props.formLink}
			</a>
			<br />
			<button
				type="button"
				className="btn btn-green btn-large btn-full mt-10"
				onClick={props.successSignup}
			>
				{`Go to ${props.businessName}'s Bizzping Admin Page`}
			</button>
		</div>
	);
};

export default Success;

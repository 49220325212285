import React from "react";

/**
 * common field error message component
 * @param props
 */
const FieldErrorMessage: React.FC<{ children?: any }> = props => {
	if (!props.children) {
		return null;
	}
	if (typeof props.children === "string") {
		return (
			<p className="error">
				{props.children}
			</p>
		);
	}
	return (
		<p className="error">
			{props.children[Object.keys(props.children)[0]]}
		</p>
	);
};

export default FieldErrorMessage;

import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import AuthService from "shared/services/auth.service";
import logo from "../../../../assets/images/logo.svg";
import { LoggedInUser } from "features/auth/interface/login.interface";

const Header: React.FC<RouteComponentProps> = (props) => {
	const isLoggedIn = AuthService.checkLogin();
	let enabledBtn = props.location.pathname.substr(1);
	const [isOpen, changeIsOpen] = React.useState(false);

	const changeOpen = () => {
		if (!isOpen) {
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}
		changeIsOpen(!isOpen);
	};

	return (
		<>
			<header className="header">
				<div className="container">
					<div className="logo">
						<Link to="/">
							<img src={logo} alt="logo" title="BizzPing" />
						</Link>
						<span className="logo-accoutn-type  hidden md:block">
							Business Owners
						</span>
					</div>
					{isLoggedIn.isAccessTokenAvailable && !isLoggedIn.auth ? (
						<div className="header-right hidden md:block">
							<span className="text-white text-base font-medium">
								Welcome{" "}
								<strong>
									{
										(AuthService.getAuthUser() as LoggedInUser)
											.name
									}
								</strong>
							</span>
						</div>
					) : (
						<div className="header-right hidden md:block">
							<Link
								className={`btn btn-${
									enabledBtn === "login" ? "green" : "black"
								}`}
								to="/login"
							>
								Login
							</Link>
							<Link
								className={`btn btn-${
									enabledBtn === "signup" ||
									(enabledBtn !== "signup" &&
										enabledBtn !== "login")
										? "green"
										: "black"
								} ml-3`}
								to="/signup"
							>
								Sign Up
							</Link>
						</div>
					)}
					<button
						onClick={changeOpen}
						className={`navbar-toggler-icon block md:hidden ${
							isOpen ? "active" : ""
						}`}
					>
						<span>toggle menu</span>
					</button>
				</div>
			</header>
			<div className={`mobile-navbar ${isOpen ? "menu-open" : ""}`}>
				<div className="mobile-navbar-inner">
					<Link
						onClick={changeOpen}
						className={`btn btn-${
							enabledBtn === "login" ? "green" : "black"
						}`}
						to="/login"
					>
						Login
					</Link>
					<Link
						className={`btn btn-${
							enabledBtn === "signup" ? "green" : "black"
						} ml-3`}
						to="/signup"
						onClick={changeOpen}
					>
						Sign Up
					</Link>
					<p className="text-xs text-white text-center mt-8">
						Need help?{" "}
						<a
							href="mailto:Info@BizzPing.com"
							className="font-semibold"
						>
							Info@BizzPing.com
						</a>
					</p>
				</div>
			</div>
		</>
	);
};

export default withRouter(Header);

import { Link, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { ThunkDispatch } from "redux-thunk";
import { toggleClass } from "../dashboard";
import { LoggedInUser } from "features/auth/interface/login.interface";
import placeholderImage from "assets/images/brand-placeholder.png";
import logo from "../../../../../assets/images/logo.svg";
import help from "../../../../../assets/images/help.svg";
import React, { useContext } from "react";
import { State, Action } from "../../../../interface";
import { connect } from "react-redux";
import authService from "shared/services/auth.service";
import LoginActions from "../../../../../features/auth/store/login.action";
import dropdownarrow from "../../../../../assets/images/dropdown-arrow-white.svg";
import { fetchCommentCount } from "queries/comments";
import { AuthContext } from "providers/AuthProvider";

interface DispatchProps {
	onLogout: () => void;
}
export const routes = {
	BASE: "",
	DASHBOARD: "/dashboard",
	FEEDBACK: "/comment",
	BUSINESS_INFO: "/business/info",
	REPRESENTATIVES: "/representatives",
	COMMENTS_FORM: "/comments",
	PROFILE: "/profile",
	HELP: "/help",
};
const user = authService.getAuthUser() as LoggedInUser;

const NavMenu: React.FC<{
	isMobile?: boolean;
	commentCount?: number;
}> = ({ isMobile, commentCount }) => {
	const history = useHistory();
	const getIsActiveClass = (route: string) => {
		const {
			location: { pathname },
		} = history;
		return pathname === route ||
			pathname === route.split(routes.DASHBOARD)[1]
			? "active"
			: "";
	};

	return (
		<ul>
			<li>
				<Link
					to={routes.FEEDBACK}
					onClick={isMobile ? toggleClass : () => {}}
					className={`dashboard-nav-item feedback-nav-link ${getIsActiveClass(
						routes.FEEDBACK
					)}`}
				>
					Comments
					<span className="nav-notification-count">
						{commentCount || 0}
					</span>
				</Link>
			</li>
			<li>
				<Link
					to={routes.DASHBOARD}
					onClick={isMobile ? toggleClass : () => {}}
					className={`dashboard-nav-item dashboard-nav-link ${getIsActiveClass(
						routes.DASHBOARD
					)}`}
				>
					Dashboard
				</Link>
			</li>
			<li>
				<Link
					onClick={isMobile ? toggleClass : () => {}}
					to={routes.BUSINESS_INFO}
					className={`dashboard-nav-item setup-nav-link ${getIsActiveClass(
						routes.BUSINESS_INFO
					)}`}
				>
					Business Info
				</Link>
			</li>

			{user.role !== "REPRESENTATIVE" && (
				<li>
					<Link
						onClick={isMobile ? toggleClass : () => {}}
						to={routes.REPRESENTATIVES}
						className={`dashboard-nav-item representative-nav-link ${getIsActiveClass(
							routes.REPRESENTATIVES
						)}`}
					>
						Representatives
					</Link>
				</li>
			)}
			<li>
				<Link
					onClick={isMobile ? toggleClass : () => {}}
					to={routes.COMMENTS_FORM}
					className={`dashboard-nav-item comment-nav-link ${getIsActiveClass(
						routes.COMMENTS_FORM
					)}`}
				>
					Comments Form
				</Link>
			</li>
		</ul>
	);
};

const SideNav: React.FC<State & DispatchProps> = (
	props: State & DispatchProps
) => {
	const user = useContext(AuthContext).user as LoggedInUser;
	const dropdownMobile = () => {
		let dropdowndiv = document.querySelector(".mobile-dropdown-menu");
		if (dropdowndiv) {
			dropdowndiv.classList.toggle("mobile-open");
		}
	};
	const { data } = useQuery("commentCount", fetchCommentCount);
	return (
		<>
			<div className="mobile-dashboard-nav">
				<div className="mobile-nav-top">
					<div
						className="dashboard-mobile-nav-profile"
						onClick={dropdownMobile}
					>
						<div className="self-start">
							<img
								src={placeholderImage}
								className="user-profile-pic"
								alt=""
								title=""
							/>
						</div>
						<div className="mr-3">
							<h6 className="header-user-name">{user.name}</h6>
							<p className="user-brand-name">
								{props.auth.profile &&
									props.auth.profile.businessName}
							</p>
						</div>
						<img
							src={dropdownarrow}
							className="dropdown-arrow-icon"
							alt=""
							title=""
						/>
					</div>
					<div className="dropdown-menu mobile-dropdown-menu">
						<ul>
							<li>
								<Link
									to="/profile"
									onClick={toggleClass}
									className="dropdown-menu-link"
								>
									Profile
								</Link>
							</li>
							{/* <li>
								<Link
									to="/"
									className="dropdown-menu-link"
									onClick={toggleClass}
								>
									Settings
								</Link>
							</li> */}
							<li>
								<Link
									to="/"
									onClick={props.onLogout}
									className="dropdown-menu-logout"
								>
									Logout
								</Link>
							</li>
						</ul>
					</div>

					<NavMenu
						isMobile
						commentCount={(data && data.needReplyCount) || 0}
					/>
				</div>
				<div className="dashboard-bottom">
					<Link to={routes.HELP}>
						<img src={help} alt="logo" title="BizzPing" />
						Need Help
					</Link>
				</div>
			</div>
			<aside className="dashboard-nav hidden lg:flex">
				<div className="dashboard-navbar">
					<div className="mb-5">
						<Link to={routes.FEEDBACK}>
							<img src={logo} alt="logo" title="BizzPing" />
						</Link>
					</div>

					<NavMenu
						commentCount={(data && data.needReplyCount) || 0}
					/>
				</div>
				<div className="dashboard-bottom">
					<Link to={routes.HELP}>
						<img src={help} alt="logo" title="BizzPing" />
						Need Help
					</Link>
				</div>
			</aside>
		</>
	);
};

const mapStateToProps = (state: State): State => state;
const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	onLogout: () => dispatch(LoginActions.logout()),
});

export default connect<State, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(SideNav);

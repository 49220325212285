import * as AWS from "aws-sdk";
import {AwsParamsSchema, AwsTempCreds} from "shared/interface";

/**
 * updateAwsConfig - updates aws configuration
 * @param awsCreds - credentials retrieved from server
 */
const updateAwsConfig = (awsCreds: AwsTempCreds) => {
	AWS.config.update({
		accessKeyId: awsCreds.credentials.AccessKeyId,
		secretAccessKey: awsCreds.credentials.SecretAccessKey,
		sessionToken: awsCreds.credentials.SessionToken,
		region: awsCreds.region
	});
	return AWS;
};

/**
 * manageUpload - returns a managedUpload object
 * @param params - aws s3 upload parameters
 */
const manageUpload = (params: AwsParamsSchema) => {
	return new AWS.S3.ManagedUpload({partSize: 5 * 1024 * 1024, params: params});
};

/**
 * uploadImage - upload image on s3, and call the callback with parameters
 */
const uploadImage = (params: AwsParamsSchema): Promise<string> => {
	const uploadToS3 = manageUpload(params);
	return new Promise((resolve, reject) => {
		uploadToS3.on("httpUploadProgress", () => {
		}).send((errS3: Error, done: { Location: string, key: string }) => {
			if (errS3 || !done || !done.Location) {
				reject(errS3 || new Error("something went wrong"));
			} else {
				resolve(done.Location);
			}
		});
	});
};

const AwsService = {
	updateAwsConfig,
	manageUpload,
	uploadImage,
};

export default AwsService;

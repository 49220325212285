import React from "react";
import { RouteComponentProps } from "react-router";
import { STEPS } from "../../../helpers";
import SignupRepresentativeForm from "./representativeForm";
import Utility from "../../../../../shared/util/utility";
import {
	Action,
	ITeamMatesFormState,
	LoaderState,
	NotificationStateProps,
	State,
} from "../../../../../shared/interface";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import * as H from "history";
import { ITeammatesParams } from "../../../interface/teammates.interface";
import TeamMateFormActions from "../../../store/teammates/actions";
import authService from "../../../../../shared/services/auth.service";
import Success from "../success";
import actionTypes from "../../../../../store/action-types";
import { getStep } from "../../../../../shared/constants/constants";

interface DispatchProps extends NotificationStateProps {
	dispatch: ThunkDispatch<{}, {}, Action>;
	onTeamMatesSubmit: (payload: ITeammatesParams, history?: H.History) => void;
	onTeamMatesSkip: (payload: ITeammatesParams, history?: H.History) => void;
}

interface IState extends ITeamMatesFormState, LoaderState {}

class RepresentativeForm extends React.Component<
	IState & RouteComponentProps & DispatchProps,
	any
> {
	componentDidMount(): void {
		this.props.dispatch(Utility.createAction(actionTypes.DISABLE_GO_NEXT));
		if (this.props.step === null) {
			return this.props.history.replace("/dashboard");
		}

		if (this.props.step && this.props.step < STEPS.REPRESENTATIVE) {
			this.props.history.replace(`/signup/${this.props.step}`);
			return;
		}
	}

	render() {
		if (
			this.props.step === null ||
			this.props.step > STEPS.REPRESENTATIVE ||
			this.props.skippedMatesForm
		) {
			return (
				<Success
					businessName={this.props.teammateData.profile.businessName}
					formLink={this.props.teammateData.formUrl}
					successSignup={this.onSuccessSignup}
				/>
			);
		}
		return (
			<SignupRepresentativeForm
				step={this.props.step}
				initialValues={this.initialValues()}
				loading={this.props.loading}
				onSubmit={this.onSubmit}
				skipTeammates={this.skipTeammates}
				history={this.props.history}
			/>
		);
	}

	initialValues = () => {
		if (this.props.step === STEPS.REPRESENTATIVE) {
			return { reps: [] };
		}
		return { reps: [] };
	};

	skipTeammates = (event: any) => {
		event.preventDefault();
		this.props.onTeamMatesSkip({ reps: [] });
	};

	onSubmit = (data: ITeammatesParams) => {
		this.props.onTeamMatesSubmit(data, this.props.history);
	};

	onSuccessSignup = (e: any) => {
		const data = { ...this.props.teammateData, showSuccessPage: false };

		authService.setTeammatesFormData(data);
		this.props.dispatch(
			Utility.createAction(actionTypes.TEAMMATE_FORM_NO_ACTION, data)
		);

		window.location.replace("/");
	};
}

const loadingSelector = Utility.createLoadingSelector(["TEAMMATES_FORM"]);

const mapStateToProps = (state: State): IState => ({
	...state.teammates,
	step: getStep(state),
	teammateData: {
		...state.teammates.teammateData,
		formUrl:
			state.feedbackForm.feedbackFormData.formUrl ||
			state.teammates.teammateData.formUrl,
	},
	loading: loadingSelector(state),
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	...Utility.getNotificationProps(dispatch),
	dispatch,
	onTeamMatesSubmit: (payload, history?) =>
		dispatch(TeamMateFormActions.signUpTeamMateForm(payload, history)),
	onTeamMatesSkip: (payload, history?) =>
		dispatch(TeamMateFormActions.signUpTeamMateSkipForm(payload, history)),
});

export default connect<IState, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(RepresentativeForm);

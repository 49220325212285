import React from "react";
import {getValue, RenderReusableInput} from "../form";
import {CheckboxFieldProps} from "./props";

const checkboxOnChange = ({value, field, form, ...rest}: any) => (e: any) => {
	if (!rest) {
		return;
	}
	if (e.target.checked) {
		if (rest.arrayHelpers) {
			rest.arrayHelpers.push(value);
		} else {
			field.onChange(field.name)([value]);
			field.onChange("checked")(true);
		}
	} else {
		if (rest.arrayHelpers) {
			rest.arrayHelpers.remove(rest.index);
		} else {
			field.onChange(field.name)([]);
			field.onChange("checked")(false);
		}
	}
	form.setFieldTouched(field.name, true);
};

const RenderInput: React.FC<CheckboxFieldProps & { field: any, form: any }> = props => {
	return (
		<>
			<input
				{...props.field}
				value={getValue(props.field.value)}
				id={props.name}
				name={props.name}
				type="checkbox"
				checked={props.field.value && props.field.value.includes(props.value)}
				className={`${props.className || "input-checkinput"}`}
				onChange={props.onChange || checkboxOnChange(props)}
			/>
			<label className="text-field-label" htmlFor={props.name}>{props.label}</label>
		</>
	);
};

/**
 * common checkbox field component
 * renders checkbox based on the field configuration
 * @param props { ...props }
 */
const ManagedCheckbox: React.FC<CheckboxFieldProps> = props => {
	return <RenderReusableInput props={props} component={RenderInput}/>;
};

export {ManagedCheckbox};

import React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RouteComponentProps, withRouter } from "react-router";
import { Formik, FormikValues } from "formik";

import * as schema from "shared/constants/validation-schema";
import Button from "shared/components/form/button";
import { Input } from "shared/components/form/inputTypes";
import { API_CONFIG } from "shared/constants/constants";
import HttpService from "shared/services/http.service";
import { Action, NotificationStateProps, State } from "shared/interface";
import Utility from "shared/util/utility";
import FormSpinner from "shared/components/spinner/formSpinner";

interface UIState {
	loading: boolean;
}

class ResetPassword extends React.Component<
	RouteComponentProps<{ token: string }> & NotificationStateProps,
	any
> {
	state: UIState = {
		loading: false,
	};

	render() {
		return (
			<div className="container">
				<div className="flex flex-wrap">
					<div className="form-white-block w-full sm:w-1/2 md:w-1/7">
						<h1 className="form-title">Reset Password</h1>
						<Formik
							initialValues={{ email: "" }}
							validateOnBlur={true}
							validateOnChange={true}
							onSubmit={this.onResetPassword}
							validationSchema={schema.resetPassword}
						>
							{({ handleSubmit, errors, touched }) => {
								const disabled =
									this.state.loading ||
									Object.keys(errors).length > 0 ||
									Object.keys(touched).length === 0;
								return (
									<form onSubmit={handleSubmit}>
										<div className="form-group">
											<label htmlFor="password">
												Password{" "}
												<span className="text-red">
													*
												</span>
											</label>
											<Input
												placeholder="password"
												type="password"
												name="password"
												showErrorMessage
											/>
										</div>

										<div className="form-group">
											<label htmlFor="confirmPassword">
												Confirm Password{" "}
												<span className="text-red">
													*
												</span>
											</label>
											<Input
												placeholder="confirm password"
												type="password"
												name="confirmPassword"
												showErrorMessage
											/>
										</div>

										<Button
											className={`btn btn-green btn-large w-56 ${
												disabled ? "disable" : ""
											}`}
											loading={this.state.loading}
											disabled={disabled}
											type="submit"
										>
											Reset Password
										</Button>
									</form>
								);
							}}
						</Formik>
						<FormSpinner isSubmitting={this.state.loading} />
					</div>
				</div>
			</div>
		);
	}

	onResetPassword = (values: FormikValues) => {
		this.setState({ loading: true });
		HttpService.post(API_CONFIG.PATH.RESET_PASSWORD, {
			password: values.password,
			token: this.props.match.params.token,
		})
			.then(() => {
				this.setState({ loading: false });
				this.props.addNotification(
					"your password has been changed",
					"success"
				);
				this.props.history.push("/login");
			})
			.catch((e) => {
				this.setState({ loading: false });
				this.props.addNotification(e.message, "error");
			});
	};
}

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): NotificationStateProps => ({
	...Utility.getNotificationProps(dispatch),
});

export default connect<{}, NotificationStateProps, {}, State>(
	null,
	mapDispatchToProps
)(withRouter(ResetPassword));

import React from "react";
import ResponsiveBar from "./ResponsiveBar";
import cloneDeep from "lodash.clonedeep";
import {
	normalizedTickValues,
	findAxisBottomTimeFormat,
	aggregateTime,
} from "./TotalFeedback";

interface IProps {
	data: any[];
	duration: string;
}

const TotalPersonalResponses: React.FC<IProps> = ({ data, duration }) => {
	const clonedData = cloneDeep(data);
	const normalizedISOtime: any = {};
	const timeAdjustedRatingData: any = [];
	let tickValues: string[] = [];
	clonedData.forEach((feedback) => {
		for (let key in feedback) {
			let normalizedDuration = aggregateTime(duration, key);
			if (!normalizedISOtime[normalizedDuration]) {
				normalizedISOtime[normalizedDuration] =
					feedback[key].totalCount;
				tickValues.push(normalizedDuration);
			} else {
				const previousRecord = normalizedISOtime[normalizedDuration];
				normalizedISOtime[normalizedDuration] =
					previousRecord + feedback[key].totalCount;
			}
		}
	});
	for (let date in normalizedISOtime) {
		timeAdjustedRatingData.push({
			date: date,
			Response: normalizedISOtime[date],
		});
	}
	tickValues = normalizedTickValues(tickValues);
	let axisBottomTimeFormat = findAxisBottomTimeFormat(duration);
	return (
		<div className="w-full lg:w-1/3 px-3">
			<div className="card bar-chart-card">
				<h4 className="chart-title mb-2">Total Business Responses</h4>
				<ResponsiveBar
					tickValues={tickValues}
					data={timeAdjustedRatingData}
					formatAxisBottom={axisBottomTimeFormat}
					tooltipString={"Comment(s)"}
					indexBy="date"
					keys={["Response"]}
				/>
			</div>
		</div>
	);
};

export default TotalPersonalResponses;

import {combineReducers} from "redux";
import login from "features/auth/store/login.reducer";
import loadingReducer from "./loadingReducer";
import commonReducer from "./commonReducer";
import {Action, State} from "shared/interface";

import actionTypes from "store/action-types";
import {feedbackFormReducer} from "../features/auth/store/feedback-form/reducer";
import {teammateReducer} from "../features/auth/store/teammates/reducer";

const appReducer = combineReducers({
	auth: login,
	loading: loadingReducer,
	common: commonReducer,
	feedbackForm: feedbackFormReducer,
	teammates: teammateReducer
});

const rootReducer = (state: State | undefined, action: Action): State => {
	if (action.type === actionTypes.AUTH_LOGOUT_SUCCESS || action.type === actionTypes.AUTH_LOGOUT_FAIL) {
		localStorage.removeItem("authData");
		localStorage.removeItem("userData");
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;

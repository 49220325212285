import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "./shared/components/errorBoundary/errorBoundary";
import App from "./App";
import store from "./store";
import AuthProvider from "providers/AuthProvider";

const Root: React.FC = React.memo((props) => {
	return (
		<Provider store={store}>
			<ErrorBoundary>
				<BrowserRouter>
					<AuthProvider>
						<App {...props} />
					</AuthProvider>
				</BrowserRouter>
			</ErrorBoundary>
		</Provider>
	);
});

export default Root;

Root.displayName = "Root Component";

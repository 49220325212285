import { ICategory } from "../../../interface/feedback-form.interface";
import { FieldArray, FieldArrayRenderProps } from "formik";
import Button from "../../../../../shared/components/form/button";
import React, { useState } from "react";
import AuthService from "shared/services/auth.service";
import { LoggedInUser } from "features/auth/interface/login.interface";
import { Checkbox } from "../../../../../shared/components/checkbox";
import { Input } from "../../../../../shared/components/form/inputTypes";

const CheckBoxCategories = ({
	name,
	categories,
	feedbackCategories,
}: {
	name: string;
	categories: ICategory[];
	feedbackCategories?: { [key: string]: ICategory };
}) => {
	const [isEdit, setEdit]: any = useState({});
	const user = AuthService.getAuthUser() as LoggedInUser;
	const isRestrictedAccess = user.role === "REPRESENTATIVE";
	const onChange = (arrayHelpers: FieldArrayRenderProps, index: number) => (
		e: any
	) => {
		const d = categories[index];
		const customCategories = arrayHelpers.form.values["custom-categories"];
		const selectedCategories = categories.filter(
			(category) => category.selected
		);
		const selectedCustomCategories = customCategories.filter(
			(category: any) => category.selected
		);
		let feedbackSelectCategory;
		if (feedbackCategories && feedbackCategories[d.id]) {
			feedbackSelectCategory = feedbackCategories![d.id];
		}
		if (e.target.checked) {
			if (
				selectedCategories.length + selectedCustomCategories.length >=
				5
			) {
				return;
			}
			d.selected = true;
			if (feedbackSelectCategory) {
				d.action = "select";
			} else {
				d.action = "add";
			}
		} else {
			d.selected = false;
			d.action = "disselect";
		}
		categories[index] = d;
		arrayHelpers.form.setFieldValue(
			name,
			categories.filter((i: ICategory) => i.selected || i.action)
		);
	};

	const onPenClick = (index: number) => () => {
		if (isRestrictedAccess) return;
		setEdit((prevEdit: any) => ({ ...prevEdit, [index]: true }));
	};

	const onBlur = (arrayHelpers: FieldArrayRenderProps, index: number) => (
		e: any
	) => {
		if (e.target.value && e.target.value.toString().trim().length > 0) {
			const d = categories[index];
			d.name = e.target.value;
			d.action = "update";
			categories[index] = d;

			arrayHelpers.form.setFieldValue(
				name,
				categories.filter((i: ICategory) => i.selected || i.action)
			);

			setEdit((prevEdit: any) => ({ ...prevEdit, [index]: false }));
		}
	};

	const onCheckClick = (index: number) => () => {
		if (isRestrictedAccess) return;
		setEdit((prevEdit: any) => ({ ...prevEdit, [index]: false }));
	};

	return (
		<FieldArray name={name}>
			{(arrayHelpers: FieldArrayRenderProps) => {
				return categories.map((category: ICategory, i: number) => {
					return (
						<div
							key={`${name}-${category.id}${i}`}
							className="feedback-category-item"
						>
							<div className="chekbox-group">
								{isEdit[i] === true ? (
									<>
										<Input
											readOnly={isRestrictedAccess}
											name={`${name}.${i}.name`}
											placeholder="Add category"
											type="text"
											className="form-control"
											onBlur={onBlur(arrayHelpers, i)}
										/>
										<Button
											disabled={false}
											loading={false}
											type="button"
											className="btn-sm btn-dark text-green"
											onClick={onCheckClick(i)}
										>
											<i className="fas fa-check-circle" />
										</Button>
									</>
								) : (
									<Checkbox
										name={`${name}.${i}.id`}
										disabled={isRestrictedAccess}
										label={category.name}
										value={category.id}
										checked={category.selected}
										onChange={onChange(arrayHelpers, i)}
									/>
								)}

								{category.editable && !isEdit[i] ? (
									<Button
										loading={false}
										type="button"
										onClick={onPenClick(i)}
										className="btn-sm btn-dark p-0 text-black"
									>
										<i className="fas fa-pencil-alt" />
									</Button>
								) : (
									""
								)}
							</div>
						</div>
					);
				});
			}}
		</FieldArray>
	);
};

export default CheckBoxCategories;

import CryptoJS from "crypto-js";
import {
	LoggedInUser,
	LoginResponse,
} from "features/auth/interface/login.interface";
import { IFeedbackFormResponse } from "../../features/auth/interface/feedback-form.interface";
import { STEPS } from "../../features/auth/helpers";
import { ITeammateFormResponse } from "../../features/auth/interface/teammates.interface";

const KEY: string = "adsfghjkla2312safaaszAS";

/**
 * function to check if user is logged in or not
 */
const checkLogin = () => {
	const accessToken = getAccessToken();
	const authData = getAuthData() as LoginResponse;
	if (
		accessToken &&
		(!authData ||
			!authData.user ||
			!authData.user.step ||
			authData.user.step > STEPS.REPRESENTATIVE)
	) {
		return {
			auth: true,
			step: null,
			isAccessTokenAvailable: !!accessToken,
		};
	}
	return {
		step:
			authData && authData.user && authData.user.step !== null
				? authData.user.step
				: null,
		auth: false,
		isAccessTokenAvailable: !!accessToken,
	};
};

/**
 * function to get user access token
 */
const getAccessToken = (): boolean | string => {
	try {
		const data = localStorage.authData;
		if (data) {
			const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
			const decryptedData: LoginResponse = JSON.parse(
				bytes.toString(CryptoJS.enc.Utf8)
			);
			return decryptedData && decryptedData.access_token
				? decryptedData.access_token
				: false;
		} else {
			return false;
		}
	} catch (e) {
		return false;
	}
};

/**
 * function to set user authentication data
 */
const setAuthData = (data: LoginResponse): void => {
	const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), KEY);
	localStorage.setItem("authData", cipherText.toString());
};

const setAuthUser = (userData: LoggedInUser): void => {
	const data = getAuthData();
	const isEmpty = Object.keys(data).length === 0;

	if (!isEmpty) {
		//@ts-ignore
		data.user = userData;
		const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), KEY);
		localStorage.setItem("authData", cipherText.toString());
	}
};

/**
 * function to get user authentication data
 */
const getAuthData = (): LoginResponse | {} => {
	const data = localStorage.authData;
	if (data) {
		const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
		return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	} else {
		return {};
	}
};

/**
 * function to get user authentication data
 */
const getAuthUser = (): LoggedInUser | {} => {
	const data = localStorage.authData;
	if (data) {
		const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
		const decryptedData: LoginResponse = JSON.parse(
			bytes.toString(CryptoJS.enc.Utf8)
		);
		return decryptedData.user;
	} else {
		return {};
	}
};

/**
 * function to remove user authentication data
 */
const removeAuthData = () => {
	localStorage.removeItem("authData");
	localStorage.clear();
};

const getFeedbackFormData = (): IFeedbackFormResponse => {
	const data = localStorage.feedbackFormData;
	if (data) {
		const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
		return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	} else {
		return {} as IFeedbackFormResponse;
	}
};

const setFeedbackFormData = (
	data: IFeedbackFormResponse,
	shouldUpdateNextStep: boolean = false,
	onDashboard: boolean = false
) => {
	const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), KEY);
	localStorage.setItem("feedbackFormData", cipherText.toString());

	if (shouldUpdateNextStep && !onDashboard) {
		const authData: LoginResponse = getAuthData() as LoginResponse;
		authData.user = { ...authData.user, step: STEPS.REPRESENTATIVE };
		setAuthData(authData);
	}
};

const getTeammateData = (): ITeammateFormResponse => {
	const data = localStorage.teammateFormData;
	if (data) {
		const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
		return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	} else {
		return {} as ITeammateFormResponse;
	}
};

const setTeammatesFormData = (data: ITeammateFormResponse) => {
	const d = getTeammateData();

	const cipherText = CryptoJS.AES.encrypt(
		JSON.stringify({ ...d, ...data }),
		KEY
	);
	localStorage.setItem("teammateFormData", cipherText.toString());

	const authData: LoginResponse = getAuthData() as LoginResponse;
	authData.user = {
		...authData.user,
		step: data.step,
		skippedMatesForm: data.skipRep,
	};
	setAuthData(authData);
};

const authService = {
	checkLogin,
	getAccessToken,
	setAuthUser,
	getAuthUser,
	setAuthData,
	getAuthData,
	removeAuthData,
	getFeedbackFormData,
	setFeedbackFormData,
	setTeammatesFormData,
	getTeammateData,
};

export default authService;

import React from "react";
import ResponsiveBar from "./ResponsiveBar";
import format from "date-fns/format";
import startOfWeek from "date-fns/startOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import startOfYear from "date-fns/startOfYear";
import cloneDeep from "lodash.clonedeep";

interface IProps {
	data: any[];
	duration: string;
}
function round(x: number) {
	return Math.ceil(x / 4) * 4;
}
export const findAxisBottomTimeFormat = (duration: string) => {
	let axisBottomTimeFormat = "";
	switch (duration) {
		case "week":
			axisBottomTimeFormat = "MMM dd";
			break;

		case "month":
			axisBottomTimeFormat = "MMM, yyyy";
			break;

		case "year":
			axisBottomTimeFormat = "yyyy";
			break;
		default:
			axisBottomTimeFormat = "MMM dd";
	}
	return axisBottomTimeFormat;
};

export const aggregateTime = (duration: string, key: string) => {
	let normalizedDuration = "";
	if (duration === "day") {
		normalizedDuration = key;
	} else if (duration === "week") {
		normalizedDuration = format(startOfWeek(new Date(key)), "yyyy-MM-dd");
	} else if (duration === "month") {
		normalizedDuration = format(startOfMonth(new Date(key)), "yyyy-MM-dd");
	} else {
		normalizedDuration = format(startOfYear(new Date(key)), "yyyy-MM-dd");
	}
	return normalizedDuration;
};
export const normalizedTickValues = (tickValues: string[]) => {
	return tickValues.filter((tick, i) => {
		if (tickValues.length <= 4) {
			return true;
		} else {
			return i % (round(tickValues.length) / 4) === 0;
		}
	});
};

const TotalFeedback: React.FC<IProps> = ({ data, duration }) => {
	const clonedData = cloneDeep(data);
	const normalizedISOtime: any = {};
	const timeAdjustedRatingData: any = [];
	let tickValues: string[] = [];
	clonedData.forEach((feedback) => {
		for (let key in feedback) {
			let normalizedDuration = "";
			if (duration === "day") {
				normalizedDuration = key;
			} else if (duration === "week") {
				normalizedDuration = format(
					startOfWeek(new Date(key)),
					"yyyy-MM-dd"
				);
			} else if (duration === "month") {
				normalizedDuration = format(
					startOfMonth(new Date(key)),
					"yyyy-MM-dd"
				);
			} else {
				normalizedDuration = format(
					startOfYear(new Date(key)),
					"yyyy-MM-dd"
				);
			}
			if (!normalizedISOtime[normalizedDuration]) {
				normalizedISOtime[normalizedDuration] = {
					markNotPrivate: feedback[key].markNotPrivate,
					markPrivate: feedback[key].markPrivate,
				};
				tickValues.push(normalizedDuration);
			} else {
				const previousRecord = normalizedISOtime[normalizedDuration];
				normalizedISOtime[normalizedDuration].markNotPrivate =
					previousRecord.markNotPrivate +
					feedback[key].markNotPrivate;
				normalizedISOtime[normalizedDuration].markPrivate =
					previousRecord.markPrivate + feedback[key].markPrivate;
			}
		}
	});
	for (let date in normalizedISOtime) {
		timeAdjustedRatingData.push({
			date: date,
			"Contact Info": normalizedISOtime[date].markNotPrivate,
			"No Contact Info": normalizedISOtime[date].markPrivate,
		});
	}
	tickValues = normalizedTickValues(tickValues);
	let axisBottomTimeFormat = findAxisBottomTimeFormat(duration);
	return (
		<div className="w-full lg:w-1/3 px-3">
			<div className="card bar-chart-card">
				<h4 className="chart-title mb-2">Total Customer Comments</h4>
				<ResponsiveBar
					data={timeAdjustedRatingData}
					tickValues={tickValues}
					formatAxisBottom={axisBottomTimeFormat}
					tooltipString={"Comment(s)"}
					indexBy="date"
					keys={["Contact Info", "No Contact Info"]}
				/>
			</div>
		</div>
	);
};

export default TotalFeedback;

import React from "react";

function Help() {
	return (
		<>
			<div className="card">
				<p className="font-medium text-base">
					See the BizzPing{" "}
					<a
						href="https://docs.google.com/document/d/1WTwo7Reqs4lF8r_D9Wt6nOdvXH-JZs37293ZmZrcRno/edit?usp=sharing"
						target="_blank"
						rel="noreferrer"
						className="link-text text-base"
					>
						User Guide
					</a>{" "}
					for details about administering your BizzPing pages or
					email&nbsp;
					<a
						href="mailto:info@BizzPing.com"
						className="link-text text-base"
					>
						info@BizzPing.com.
					</a>
				</p>
			</div>
		</>
	);
}

export default Help;

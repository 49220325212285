import { Link, RouteComponentProps } from "react-router-dom";
import dropdownarrow from "../../../assets/images/dropdown-arrow.svg";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { ThunkDispatch } from "redux-thunk";
import { Action, State } from "../../../shared/interface";
import LoginActions from "../../auth/store/login.action";
import { connect } from "react-redux";
import Utility from "../../../shared/util/utility";
import placeholderImage from "assets/images/brand-placeholder.png";
import { LoggedInUser } from "features/auth/interface/login.interface";
import { fetchCommentCount } from "queries/comments";
import { AuthContext } from "providers/AuthProvider";

export interface DispatchProps {
	onLogout: () => void;
}
const routePathNameMap = (pathName: string) => {
	let pageTitle;
	switch (pathName) {
		case "/help":
		case "/help/":
			pageTitle = "Help";
			break;
		case "/profile":
		case "/profile/":
			pageTitle = "My Profile";
			break;
		case "/comment":
		case "/comment/":
			pageTitle = "Comments";
			break;
		case "/representatives":
		case "/representatives/":
			pageTitle = "Representatives";
			break;
		case "/business/info":
		case "/business/info/":
			pageTitle = "Business Info";
			break;
		case "/comments":
		case "/comments/":
			pageTitle = "Comments form";
			break;
		default:
			pageTitle = "Dashboard";
	}
	return pageTitle;
};
const DashboardHeader: React.FC<any> = (
	props: DispatchProps & RouteComponentProps & State & { pathName: string }
) => {
	const { pathName } = props;
	const user = useContext(AuthContext).user as LoggedInUser;

	const dropdown = () => {
		let dropdowndiv = document.querySelector(".dropdown-menu-large");
		if (dropdowndiv) {
			dropdowndiv.classList.toggle("open");
		}
	};

	const onLogout = (e: any) => {
		e.preventDefault();
		props.onLogout();
	};
	const pageTitle = routePathNameMap(pathName);
	const isDashboard = pageTitle === "Dashboard";
	const { data } = useQuery("commentCount", fetchCommentCount, {
		enabled: isDashboard,
	});

	return (
		<div className="dashboard-header">
			<div className="w-full md:w-1/2">
				{isDashboard ? (
					<>
						<h1 className="dashboard-user-title">Dashboard</h1>
						<p>
							Welcome back! You have{" "}
							<Link to="/comment" className="underline">
								{(data && data.needReplyCount) || 0} Response
							</Link>{" "}
							pending for today
						</p>
					</>
				) : (
					<h1 className="dashboard-user-title">{pageTitle}</h1>
				)}
			</div>

			<div className="w-full md:w-1/2">
				<div className="dashboard-header-right">
					<div className="header-user-account" onClick={dropdown}>
						<div className="self-start">
							<img
								src={placeholderImage}
								className="user-profile-pic"
								alt=""
								title=""
							/>
						</div>
						<div className="mr-3">
							<h6 className="header-user-name">{user.name}</h6>
							<p className="user-brand-name">
								{props.auth.profile &&
									props.auth.profile.businessName}
							</p>
						</div>
						<img
							src={dropdownarrow}
							className="dropdown-arrow-icon"
							alt=""
							title=""
						/>
						<div className="dropdown-menu dropdown-menu-large">
							<ul>
								<li>
									<Link
										to="/profile"
										className="dropdown-menu-link"
									>
										Profile
									</Link>
								</li>
								{/* <li>
									<Link to="/" className="dropdown-menu-link">
										Settings
									</Link>
								</li> */}
								<li>
									<Link
										to="#"
										onClick={onLogout}
										className="dropdown-menu-logout"
									>
										Logout
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const loadingSelector = Utility.createLoadingSelector(["AUTH_LOGOUT"]);

const mapStateToProps = (state: State): State & { isLoading: boolean } => ({
	...state,
	isLoading: loadingSelector(state),
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	onLogout: () => dispatch(LoginActions.logout()),
});

export default connect<
	State & { isLoading: boolean },
	DispatchProps,
	{},
	State
>(
	mapStateToProps,
	mapDispatchToProps
)(DashboardHeader);

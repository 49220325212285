import React, { useState } from "react";
import { RouteComponentProps, Redirect } from "react-router";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import datepickericon from "../../../assets/images/date-picker.svg";
import ResponsiveLine from "../components/ResponsiveLine";
import { useQuery } from "react-query";
import { fetchDashboardData } from "queries/dashboard";
import TotalFeedback from "../components/TotalFeedback";
import TotalPersonalResponses from "../components/TotalResponses";
import TotalResponseTime from "../components/TotalResponseTime";

const options = [
	{ value: "day", label: "Day" },
	{ value: "week", label: "Week" },
	{ value: "month", label: "Month" },
	{ value: "year", label: "Year" },
];
const HomeDashboard: React.FC<RouteComponentProps> = (props) => {
	const redirectState = localStorage.getItem("redirectUrl");
	const [startDate, setStartDate] = useState(
		new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
	);
	const [endDate, setEndDate] = useState(new Date());
	const [duration, setDuration] = useState({
		value: "day",
		label: "Day",
	});

	const { data } = useQuery(
		[
			"dashboardData",
			{
				fromDate: startDate,
				toDate: endDate,
			},
		],
		fetchDashboardData,
		{
			initialData: {
				ratingCategories: [],
				totalFeedback: [],
				totalPersonalResponse: [],
				averagePersonalResponseTime: [],
			},
			initialStale: true,
		}
	);
	const {
		ratingCategories,
		totalFeedback,
		totalPersonalResponse,
		averagePersonalResponseTime,
	} = data;
	if (redirectState) {
		localStorage.removeItem("redirectUrl");
		return (
			<Redirect to={JSON.parse(redirectState).from || redirectState} />
		);
	}
	return (
		<>
			<div className="dashboard-date-filter">
				<div className="filter-item">
					<label htmlFor="fromDate">FROM</label>
					<span className="filter-date-item">
						<img
							src={datepickericon}
							className="datepicker-icon"
							alt="date icon"
						/>
						<DatePicker
							onChange={(value) => {
								if (value) setStartDate(value);
							}}
							selected={startDate}
							className="custom-datepicker"
							maxDate={new Date()}
							popperClassName="datepicker"
							dateFormat="d MMM,yyyy"
							name="fromDate"
						/>
					</span>
				</div>
				<div className="filter-item">
					<label htmlFor="toDate">to</label>
					<span className="filter-date-item">
						<img
							src={datepickericon}
							className="datepicker-icon"
							alt="date icon"
						/>
						<DatePicker
							selected={endDate}
							onChange={(value) => {
								if (value) setEndDate(value);
							}}
							maxDate={new Date()}
							className="custom-datepicker"
							popperClassName="datepicker"
							dateFormat="d MMM,yyyy"
							name="toDate"
						/>
					</span>
				</div>
				<div className="filter-item  filter-week">
					<label htmlFor="duration">Duration</label>
					<Select
						options={options}
						isSearchable={false}
						onChange={(option: any) => setDuration(option)}
						className=""
						classNamePrefix="filter-week-select"
						value={duration}
						name="duration"
					/>
				</div>
			</div>
			<div className="flex flex-wrap -mx-3">
				<TotalFeedback data={totalFeedback} duration={duration.value} />
				<TotalPersonalResponses
					data={totalPersonalResponse}
					duration={duration.value}
				/>
				<TotalResponseTime
					data={averagePersonalResponseTime}
					duration={duration.value}
				/>
			</div>

			<div className="card line-chart-block">
				<h4 className="chart-title mb-5 ml-3">Rating Categories</h4>

				<ResponsiveLine
					ratingData={ratingCategories}
					duration={duration.value}
				/>
			</div>
		</>
	);
};

export default HomeDashboard;
